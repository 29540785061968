import { useNavigate, useParams } from 'react-router-dom';
import style from '../../../styles/style.module.css';
import {
  Tabs,
  TextInput,
  Avatar,
  TagsInput,
  LoadingOverlay,
  Loader,
  Pagination,
  Select,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { notifications } from '@mantine/notifications';
import {
  apiArtwork,
  apiEditArtwork,
  apiProfile,
  apiSingleArtist,
  getPresignedUrl,
} from '../../../api/ApiServices';
import { FaArrowLeft, FaCamera, FaPlus } from 'react-icons/fa6';
import { FaTimes } from 'react-icons/fa';
import ArtworkDetail from './ArtworkDetail';
import Exhibitions from './Exhibition';
import Profile from './Profile';
import dayjs from 'dayjs';

const ArtistDetails = () => {
  const { id } = useParams();
  const [singleArtist, setSingleArtist] = useState<Artist>();
  const [isFormView, setIsFormView] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewWork, setViewWork] = useState(false);
  const [artworkImages, setArtworkImages] = useState<string[]>([]);
  const [selectedArtwork, setSelectedArtwork] = useState<Work>();
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [editingArtwork, setEditingWork] = useState(false);
  const [artistExhb, setArtistExhb] = useState<Exhibition[]>([]);
  const [artistWork, setArtistWork] = useState<Work[]>([]);

  // Get current artwork
  const indexOfLastArtwork = currentPage * itemsPerPage;
  const indexOfFirstArtwork = indexOfLastArtwork - itemsPerPage;
  const currentArtwork = artistWork?.slice(
    indexOfFirstArtwork,
    indexOfLastArtwork
  );
  // Change page
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  // get single artist
  const getSingleArtist = async () => {
    setLoading(true);
    try {
      const resp = await apiSingleArtist(id as string);
      setSingleArtist(resp.artist);
      setArtistExhb(resp.artist.exhibitions as Exhibition[]);
      setArtistWork(resp.artist.works as Work[]);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleArtist();
  }, []);

  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const createModal = () => {
    setIsFormView(true);
  };
  const editModal = () => {
    setIsFormView(true);
    setEditingWork(true);
  };
  const form = useForm({
    initialValues: {
      title: '',
      year: '',
      categories: '',
      materials: [] as string[],
      dimensionLengthInCM: '',
      dimensionWidthInCM: '',
    },
    validate: {
      title: (value) => (value.trim() ? null : 'Artwork Title is required'),
      year: (value) => (value.trim() ? null : 'Year of Creation is required'),
      categories: (value) =>
        value.length > 0 ? null : 'At least one category is required',
      materials: (value) =>
        value.length > 0 ? null : 'At least one material is required',
      dimensionLengthInCM: (value) =>
        value ? null : 'Artwork Dimension (Length) is required',
      dimensionWidthInCM: (value) =>
        value ? null : 'Artwork Dimension (Width) is required',
    },
  });

  useEffect(() => {
    if (selectedArtwork) {
      form.setValues({
        title: selectedArtwork.title || '',
        year: selectedArtwork.year
          ? dayjs(selectedArtwork.year).format('YYYY-MM-DD')
          : '',
        categories: selectedArtwork.categories[0] || '',
        materials: selectedArtwork.materials || [],
        dimensionLengthInCM: selectedArtwork.dimensionLengthInCM || '',
        dimensionWidthInCM: selectedArtwork.dimensionWidthInCM || '',
      });
      if (selectedArtwork.previewImage) {
        setPreviewImage(selectedArtwork.previewImage);
        setFiles((prev) => ({
          ...prev,
          preview: selectedArtwork.previewImage,
        }));
      }
      // Set the additional images
      if (selectedArtwork.images && selectedArtwork.images.length > 0) {
        setAdditionalImages(selectedArtwork.images);
        setFiles((prev) => ({
          ...prev,
          additional: selectedArtwork.images,
        }));
      }
    }
  }, [selectedArtwork]);

  const fileInputRefPreview = useRef<HTMLInputElement>(null);
  const fileInputRefOthers = useRef<HTMLInputElement>(null);
  const fileInputRefBio = useRef<HTMLInputElement>(null);

  const handlePreviewImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setPreviewImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'preview');
    }
  };

  const handleProfileImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setProfileImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'profile');
    }
  };

  const handleOtherImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      for (const file of Array.from(files)) {
        try {
          await uploadImage(file, 'additional');
        } catch (error) {
          console.error('Error uploading image:', error);
          notifications.show({
            title: 'Upload Error',
            color: 'red',
            message: 'An error occurred while uploading the image.',
          });
        }
      }
    }
  };

  const uploadImage = async (
    file: File,
    type: 'preview' | 'additional' | 'profile'
  ) => {
    setIsLoading(true);
    try {
      const resp = await getPresignedUrl(file.name);
      console.log(resp);
      const uploadResponse = await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      if (!uploadResponse.ok) {
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          mt: '100px',
          message: 'An error occurred, please try again later.',
        });
        return;
      }
      if (type === 'preview') {
        setFiles((prev) => ({
          ...prev,
          preview: resp.fileName,
        }));
        setPreviewImage(URL.createObjectURL(file));
      } else if (type === 'profile') {
        setFiles((prev) => ({ ...prev, profile: resp.fileName }));
        setProfileImage(URL.createObjectURL(file));
      } else {
        setFiles((prev) => ({
          ...prev,
          additional: [...prev.additional, resp.fileName],
        }));
        setAdditionalImages((prev) => [...prev, URL.createObjectURL(file)]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleSubmitProfileImage = async () => {
    setIsLoading(true);
    try {
      const payload = {
        image: files.profile,
      };
      const resp = await apiProfile(id as string, payload);
      notifications.show({
        title: 'Profile Updated',
        color: 'green',
        mt: '100px',
        message: 'Profile Image successfully Updated',
      });
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const removeAdditionalImage = (index: number) => {
    setAdditionalImages((prev) => prev.filter((_, i) => i !== index));
    setFiles((prev) => ({
      ...prev,
      additional: prev.additional.filter((_, i) => i !== index),
    }));
  };

  const [files, setFiles] = useState<{
    preview: string;
    additional: string[];
    profile: string;
  }>({
    preview: selectedArtwork?.previewImage || '',
    additional: selectedArtwork?.images || [],
    profile: selectedArtwork?.image || '',
  });

  const closeModal = () => {
    setIsFormView(false);
    setEditingWork(false);
    setPreviewImage(null);
    setArtworkImages([]);
    setViewWork(false);
    setFiles({ preview: '', additional: [], profile: '' });
  };

  const handleViewWorks = (artwork: Work) => {
    setSelectedArtwork(artwork);
    console.log(selectedArtwork?.artist._id, 'here');
    setSelectedImage(artwork.previewImage);
    setViewWork(true);
  };

  const buildEditPayload = async (
    formValues: any,
    selectedArtwork: any,
    files: any
  ) => {
    const payload: any = {};

    // Check for changes in all form fields
    for (const key in formValues) {
      if (formValues[key] !== selectedArtwork[key]) {
        payload[key] = formValues[key];
      }
    }

    // Handle preview image
    if (files.preview !== selectedArtwork.previewImage) {
      payload.previewImage = files.preview;
    }

    // Handle additional images
    const addedImages = files.additional.filter(
      (img: string) => !selectedArtwork.images.includes(img)
    );
    const removedImages = selectedArtwork.images.filter(
      (img: string) => !files.additional.includes(img)
    );
    if (addedImages.length > 0) {
      payload.imagesToBeAdded = addedImages;
    }
    if (removedImages.length > 0) {
      payload.imagesToBeRemoved = removedImages;
    }

    // Handle categories
    const addedCategories = formValues.categories.filter(
      (id: string) => !selectedArtwork.categories.includes(id)
    );
    const removedCategories = selectedArtwork.categories.filter(
      (id: string) => !formValues.categories.includes(id)
    );
    if (addedCategories.length > 0) {
      payload.categoriesToBeAdded = addedCategories;
    }
    if (removedCategories.length > 0) {
      payload.categoriesToBeRemoved = removedCategories;
    }

    return payload;
  };

  const buildCreatePayload = (formValues: any, files: any) => ({
    ...formValues,
    previewImage: files.preview,
    images: files.additional,
  });

  const handleSubmit = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      console.log('Form has errors', validationResult.errors);
      return;
    }

    if (!files.preview || files.additional.length === 0) {
      notifications.show({
        title: 'Missing Images',
        color: 'red',
        mt: '100px',
        message: 'Please upload both preview and additional images.',
      });
      return;
    }

    setLoading(true);
    try {
      const payload = editingArtwork
        ? await buildEditPayload(form.values, selectedArtwork, files)
        : buildCreatePayload(form.values, files);

      let response;
      if (editingArtwork) {
        response = await apiEditArtwork(
          selectedArtwork?._id as string,
          payload
        );
        setSelectedArtwork(response.updatedWork);
      } else {
        response = await apiArtwork(payload, id as string);
      }

      setLoading(false);
      notifications.show({
        title: editingArtwork ? 'Artist Edited' : 'Artist Created',
        color: 'green',
        mt: '100px',
        message: editingArtwork
          ? 'Artwork successfully Edited'
          : 'Artwork Successfully Created ',
      });
      closeModal();
      getSingleArtist();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={style.main}>
      <div className='mb-16 '>
        <Tabs color='#DA3400' defaultValue='artworks'>
          <Tabs.List className='mb-7 flex justify-between'>
            <Tabs.Tab value='artworks' className='px-0 '>
              Artworks
            </Tabs.Tab>
            <Tabs.Tab value='biography'>Biography</Tabs.Tab>
            <Tabs.Tab value='exhibitions'>Exhibitions</Tabs.Tab>
            <Tabs.Tab value='profile'>Profile</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='artworks' className='mt-10'>
            <div className='flex justify-between items-center mb-6 relative'>
              <div className='flex items-center '>
                <button
                  className='mr-4 text-xs font-normal flex'
                  onClick={
                    isFormView ? closeModal : viewWork ? closeModal : goback
                  }>
                  <span className='my-auto ml- mr-2'>
                    <FaArrowLeft />
                  </span>
                  Go Back
                </button>

                <h1 className=''>
                  <span className='text-sm font-normal'>Artist</span> /{' '}
                  <span className={viewWork ? '' : 'font-bold'}>
                    {singleArtist?.firstName} {singleArtist?.lastName}
                  </span>
                  {viewWork ? (
                    <span className='font-bold '> / Artwork</span>
                  ) : null}
                </h1>
              </div>
              {isFormView ? null : (
                <button
                  className='rounded-md py-2 px-5 bg-[#DA3400] text-white'
                  onClick={createModal}>
                  Add Artwork
                </button>
              )}
            </div>
            {loading ? (
              <div className='flex justify-center text-center items-center h-[70vh] my-auto'>
                <Loader size={40} />
              </div>
            ) : isFormView ? (
              <div className='w-4/5 mt-12 mx-5 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
                <LoadingOverlay
                  visible={isLoading}
                  zIndex={1000}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                  loaderProps={{ color: 'red', type: 'bars' }}
                />
                <form onSubmit={form.onSubmit(handleSubmit)}>
                  <div className='mb-4'>
                    <div className='flex justify-around text-center mb-10'>
                      <div className='mx-auto justify-center'>
                        <Avatar
                          size='xl'
                          src={
                            selectedArtwork?.previewImage || previewImage || ''
                          }
                          alt='preview Image Work'
                          className='mx-auto cursor-pointer'
                          onClick={() => fileInputRefPreview.current?.click()}>
                          <FaCamera />
                        </Avatar>
                        <p className='text-sm text-[#DA3400] mt-1'>
                          Upload Artwork Preview Image
                        </p>
                      </div>
                    </div>
                    <input
                      ref={fileInputRefPreview}
                      name='previewImage'
                      id='previewImageUpload'
                      type='file'
                      accept='image/*'
                      onChange={handlePreviewImageUpload}
                      className='hidden cursor-pointer'
                    />
                  </div>
                  <div className='grid grid-cols-2 gap-y-8  gap-x-5'>
                    <TextInput
                      label='Artwork Title'
                      placeholder='Artwork Title'
                      {...form.getInputProps('title')}
                    />
                    <TextInput
                      label='Year of Creation'
                      type='date'
                      placeholder='Year of Creation'
                      {...form.getInputProps('year')}
                    />
                    <Select
                      label='Artwork Category'
                      placeholder='Select categories'
                      data={['single', 'mixed media']}
                      {...form.getInputProps('categories')}
                    />
                    <TagsInput
                      label='Artwork Material'
                      placeholder='Enter materials'
                      data={[]}
                      {...form.getInputProps('materials')}
                    />
                    <TextInput
                      label='Artwork Dimension (Length)'
                      type='number'
                      placeholder='Artwork Dimension (Length)'
                      {...form.getInputProps('dimensionLengthInCM')}
                    />
                    <TextInput
                      label='Artwork Dimension (Width)'
                      type='number'
                      placeholder='Artwork Dimension (Width)'
                      {...form.getInputProps('dimensionWidthInCM')}
                    />
                  </div>
                  <div className='mt-8'>
                    <small>Additional Images</small>
                    <div className='flex flex-wrap gap-5 mt-2'>
                      {additionalImages.map((image, index) => (
                        <div
                          key={index}
                          className='relative shadow-lg border-[1px] px-4 py-2 rounded-md'>
                          <img
                            src={image}
                            alt={`Additional Image ${index + 1}`}
                            className='w-20 h-20 object-cover rounded-full'
                          />
                          <button
                            type='button'
                            onClick={(e) => {
                              e.stopPropagation();
                              removeAdditionalImage(index);
                            }}
                            className='absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1'>
                            <FaTimes size={12} />
                          </button>
                          {/* <p className='text-xs text-[#DA3400] mt-1 text-center'>
                            Change Image
                          </p> */}
                        </div>
                      ))}
                      <div className='shadow-lg border-[1px] px-4 py-2 rounded-md'>
                        <Avatar
                          size='lg'
                          src=''
                          alt='Upload New Image'
                          className='mx-auto cursor-pointer'
                          onClick={() => fileInputRefOthers.current?.click()}>
                          <FaPlus />
                        </Avatar>
                        <p className='text-xs text-[#DA3400] mt-1'>Add Image</p>
                      </div>
                    </div>
                    <input
                      ref={fileInputRefOthers}
                      name='images'
                      id='otherImageUpload'
                      type='file'
                      accept='image/*'
                      multiple
                      onChange={handleOtherImages}
                      className='hidden'
                    />
                  </div>
                  <div className='flex justify-end'>
                    <button
                      type='submit'
                      disabled={loading}
                      className={`px-5 mt-16 bg-[#DA3400] text-white py-3 rounded-lg font-bold ${
                        loading ? 'cursor-disable' : 'cursor-pointer'
                      }`}>
                      {viewWork ? 'Save Artwork' : 'Create Artwork'}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <>
                {viewWork ? (
                  <div className='mt-12'>
                    {selectedArtwork && (
                      <ArtworkDetail
                        edit={editModal}
                        artwork={selectedArtwork}
                        selectedImage={selectedImage}
                        onImageSelect={(img) => setSelectedImage(img)}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {currentArtwork?.length === 0 ? (
                      <>
                        <div className='flex justify-center h-[80vh] my-auto '>
                          <div className='my-auto text-center'>
                            <p className='font-bold text-xl mb-3 mx-auto'>
                              No artwork has been created for this artist.
                            </p>
                            <button
                              onClick={createModal}
                              className='px-40 bg-[#DA3400] text-white py-2 rounded-lg font-bold'>
                              Add Artworks
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className='grid grid-cols-3 gap-10 mb-12 relative'>
                        {currentArtwork?.map((artwork, index) => (
                          <div
                            key={index}
                            className='mt-1 p-4 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
                            <img
                              src={artwork.previewImage}
                              className='w-full h-64 object-cover rounded-md'
                              alt={artwork.title}
                            />
                            <h1 className='mt-2 text-lg font-bold'>
                              {artwork.title.toUpperCase()}
                            </h1>
                            <h1 className='mt-2'>
                              {artwork.categories.join(', ')}
                            </h1>
                            <h1 className='mt-2'>
                              {artwork.materials.join(', ')}
                            </h1>
                            <h1 className='mt-2'>
                              {artwork.dimensionLengthInCM}" x{' '}
                              {artwork.dimensionWidthInCM}"
                            </h1>
                            <div className='flex justify-end'>
                              <button
                                onClick={() => handleViewWorks(artwork)}
                                className='rounded-md py-1 px-5 bg-[#DA3400] text-white'>
                                View
                              </button>
                            </div>
                          </div>
                        ))}

                        {artistWork.length > itemsPerPage ? (
                          <div className='absolute -bottom-20 md:right-0 right-2 '>
                            <Pagination
                              total={Math.ceil(
                                artistWork.length / itemsPerPage
                              )}
                              value={currentPage}
                              onChange={handlePageChange}
                              color='#DA3400'
                              radius='lg'
                              className=' '
                              mt={30}
                              px={0}
                              styles={{
                                control: {
                                  margin: '6px',
                                },
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </Tabs.Panel>

          <Tabs.Panel value='biography'>
            <div className='flex items-center mb-4 mt-3'>
              <button
                className='mr-4 text-xs font-normal flex'
                onClick={goback}>
                <span className='my-auto ml- mr-2'>
                  <FaArrowLeft />
                </span>
                Go Back
              </button>
              <h1 className='font-bold text-lg'>
                <span className='text-sm font-normal'>Artist</span> /{' '}
                {singleArtist?.firstName} {singleArtist?.lastName}
              </h1>
            </div>
            <div className='p-5 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] relative'>
              <LoadingOverlay
                visible={isLoading}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: 'red', type: 'bars' }}
              />
              <div className='flex flex-col text-center mt-3 mb-5'>
                <Avatar
                  src={profileImage || singleArtist?.image || ''}
                  alt='artist image'
                  className='w-28 h-28 mx-auto cursor-pointer'
                  onClick={() => fileInputRefBio.current?.click()}
                />
                <p
                  className='text-[#DA3400] text-sm my-2 font-bold cursor-pointer'
                  onClick={() => fileInputRefBio.current?.click()}>
                  Change Artist's Profile Image
                </p>
                <input
                  ref={fileInputRefBio}
                  type='file'
                  accept='image/*'
                  onChange={handleProfileImageUpload}
                  className='hidden'
                />
              </div>
              <p>{singleArtist?.fullBiography}</p>
              <div className='flex justify-end mt-10'>
                <button
                  type='submit'
                  disabled={loading}
                  className={`px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold ${
                    loading ? 'cursor-disable' : 'cursor-pointer'
                  }`}
                  onClick={() => handleSubmitProfileImage()}>
                  Save Changes
                </button>
              </div>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value='exhibitions'>
            <Exhibitions artistExhb={artistExhb} fetchExhb={getSingleArtist} />
          </Tabs.Panel>
          <Tabs.Panel value='profile'>
            <Profile profile={singleArtist as Artist} />
          </Tabs.Panel>
        </Tabs>
      </div>
    </section>
  );
};

export default ArtistDetails;
