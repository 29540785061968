import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@mantine/core/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';


const theme: MantineThemeOverride = {
  fontFamily: 'Abhaya Libre',
  headings: { fontFamily: 'Abhaya Libre' },
};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <MantineProvider theme={theme}>
    <App />
    <Notifications position='top-right' />
  </MantineProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
