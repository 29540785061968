import { notifications } from '@mantine/notifications';
import {
  apiArtwork,
  apiEditArtwork,
  apiGetAllArtwork,
  getPresignedUrl,
} from '../../api/ApiServices';
import style from '../../styles/style.module.css';
import { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Center,
  Loader,
  LoadingOverlay,
  Pagination,
  Select,
  TagsInput,
  TextInput,
} from '@mantine/core';
import ArtworkDetail from '../artist/details/ArtworkDetail';
import { useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { FaTimes } from 'react-icons/fa';
import { FaArrowLeft, FaCamera, FaPlus } from 'react-icons/fa6';
import dayjs from 'dayjs';

const Index = () => {
  const [allArtworks, setAllArtworks] = useState<Work[]>([]);
  const [loading, setLoading] = useState(false);
  const [artworkDetail, setArtworkDetail] = useState(false);
  const [selectedWork, setSelectedWork] = useState<Work>();
  const [selectedImage, setSelectedImage] = useState<string>('');
  const { id } = useParams();
  const [isFormView, setIsFormView] = useState(false);
  const [editingArtwork, setEditingArtwork] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewWork, setViewWork] = useState(false);
  const [artworkImages, setArtworkImages] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [pageChanging, setPageChanging] = useState(false);

  // Get current artwork
  const indexOfLastArtwork = currentPage * itemsPerPage;
  const indexOfFirstArtwork = indexOfLastArtwork - itemsPerPage;
  const currentArtwork = allArtworks?.slice(
    indexOfFirstArtwork,
    indexOfLastArtwork
  );
  // Change page
  const handlePageChange = (newPage: number) => {
    setPageChanging(true);
    setCurrentPage(newPage);
    setTimeout(() => {
      setPageChanging(false);
    }, 500);
  };
  const handleViewWorks = (allArtworks: Work) => {
    setArtworkDetail(true);
    setSelectedImage(allArtworks.previewImage);
    setSelectedWork(allArtworks);
  };

  const allArtwork = async () => {
    setLoading(true);
    try {
      const resp = await apiGetAllArtwork();
      setAllArtworks(resp.works);
      console.log(resp.works);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    allArtwork();
  }, []);

  const createModal = () => {
    setIsFormView(true);
  };

  const editModal = () => {
    setIsFormView(true);
    setEditingArtwork(true);
  };

  const closeModal = () => {
    setIsFormView(false);
    setEditingArtwork(false);
    setPreviewImage(null);
    setArtworkImages([]);
    setArtworkDetail(false);
    form.reset();
    setViewWork(false);
    setFiles({ preview: '', additional: [] });
  };

  const form = useForm({
    initialValues: {
      title: '',
      year: '',
      categories: '',
      materials: [] as string[],
      dimensionLengthInCM: '',
      dimensionWidthInCM: '',
    },
    validate: {
      title: (value) => (value.trim() ? null : 'Artwork Title is required'),
      year: (value) => (value.trim() ? null : 'Year of Creation is required'),
      categories: (value) =>
        value.length > 0 ? null : 'At least one category is required',
      materials: (value) =>
        value.length > 0 ? null : 'At least one material is required',
      dimensionLengthInCM: (value) =>
        value ? null : 'Artwork Dimension (Length) is required',
      dimensionWidthInCM: (value) =>
        value ? null : 'Artwork Dimension (Width) is required',
    },
  });

  useEffect(() => {
    if (selectedWork) {
      setIsLoading(true);
  
      form.setValues({
        title: selectedWork.title || '',
        year: selectedWork.year
          ? dayjs(selectedWork.year).format('YYYY-MM-DD')
          : '',
        categories: selectedWork.categories[0],

        materials: selectedWork.materials || [],
        dimensionLengthInCM: selectedWork.dimensionLengthInCM || '',
        dimensionWidthInCM: selectedWork.dimensionWidthInCM || '',
      });
      if (selectedWork.previewImage) {
        setPreviewImage(selectedWork.previewImage);
        setFiles((prev) => ({
          ...prev,
          preview: selectedWork.previewImage,
        }));
      }

      // Set the additional images
      if (selectedWork.images && selectedWork.images.length > 0) {
        setAdditionalImages(selectedWork.images);
        setFiles((prev) => ({
          ...prev,
          additional: selectedWork.images,
        }));

        setIsLoading(false);
      }
    }
  }, [selectedWork]);

  const fileInputRefPreview = useRef<HTMLInputElement>(null);
  const fileInputRefOthers = useRef<HTMLInputElement>(null);

  const handlePreviewImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setPreviewImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'preview');
    }
  };

  const handleOtherImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      for (const file of Array.from(files)) {
        try {
          await uploadImage(file, 'additional');
        } catch (error) {
          console.error('Error uploading image:', error);
          notifications.show({
            title: 'Upload Error',
            color: 'red',
            message: 'An error occurred while uploading the image.',
          });
        }
      }
    }
  };

  const removeAdditionalImage = (index: number) => {
    setAdditionalImages((prev) => prev.filter((_, i) => i !== index));
    setFiles((prev) => ({
      ...prev,
      additional: prev.additional.filter((_, i) => i !== index),
    }));
  };

  const [files, setFiles] = useState<{
    preview: string;
    additional: string[];
  }>({
    preview: selectedWork?.previewImage || '',
    additional: selectedWork?.images || [],
  });

  const uploadImage = async (file: File, type: 'preview' | 'additional') => {
    setIsLoading(true);
    try {
      const resp = await getPresignedUrl(file.name);
      console.log(resp);
      const uploadResponse = await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      if (!uploadResponse.ok) {
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          mt: '100px',
          message: 'An error occurred, please try again later.',
        });
        return;
      }
      if (type === 'preview') {
        setFiles((prev) => ({
          ...prev,
          preview: resp.fileName,
        }));
        setPreviewImage(URL.createObjectURL(file));
      } else {
        setFiles((prev) => ({
          ...prev,
          additional: [...prev.additional, resp.fileName],
        }));
        setAdditionalImages((prev) => [...prev, URL.createObjectURL(file)]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const buildEditPayload = async (
    formValues: any,
    selectedArtwork: any,
    files: any
  ) => {
    const payload: any = {};

    // Check for changes in all form fields
    for (const key in formValues) {
      if (formValues[key] !== selectedArtwork[key]) {
        payload[key] = formValues[key];
      }
    }

    // Handle preview image
    if (files.preview !== selectedArtwork.previewImage) {
      payload.previewImage = files.preview;
    }

    // Handle additional images
    const addedImages = files.additional.filter(
      (img: string) => !selectedArtwork.images.includes(img)
    );
    const removedImages = selectedArtwork.images.filter(
      (img: string) => !files.additional.includes(img)
    );
    if (addedImages.length > 0) {
      payload.imagesToBeAdded = addedImages;
    }
    if (removedImages.length > 0) {
      payload.imagesToBeRemoved = removedImages;
    }

    // Handle categories
    const addedCategories = formValues.categories.filter(
      (id: string) => !selectedArtwork.categories.includes(id)
    );
    const removedCategories = selectedArtwork.categories.filter(
      (id: string) => !formValues.categories.includes(id)
    );
    if (addedCategories.length > 0) {
      payload.categoriesToBeAdded = addedCategories;
    }
    if (removedCategories.length > 0) {
      payload.categoriesToBeRemoved = removedCategories;
    }

    return payload;
  };

  const buildCreatePayload = (formValues: any, files: any) => ({
    ...formValues,
    previewImage: files.preview,
    images: files.additional,
  });

  const handleSubmit = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      console.log('Form has errors', validationResult.errors);
      return;
    }

    if (!files.preview || files.additional.length === 0) {
      notifications.show({
        title: 'Missing Images',
        color: 'red',
        mt: '100px',
        message: 'Please upload both preview and additional images.',
      });
      return;
    }

    setLoading(true);
    try {
      const payload = editingArtwork
        ? await buildEditPayload(form.values, selectedWork, files)
        : buildCreatePayload(form.values, files);

      let response;
      if (editingArtwork) {
        response = await apiEditArtwork(selectedWork?._id as string, payload);
        console.log(selectedWork, 'selected');
        setSelectedWork(response.updatedWork);
      } else {
        response = await apiArtwork(payload, id as string);
      }
      setLoading(false);
      notifications.show({
        title: editingArtwork ? 'Artist Edited' : 'Artist Created',
        color: 'green',
        mt: '100px',
        message: editingArtwork
          ? 'Artwork successfully Edited'
          : 'Artwork Successfully Created ',
      });
      closeModal();
      allArtwork();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={`${style.main}`}>
      {artworkDetail || isFormView ? null : (
        <h1 className='font-bold text-lg mt-3 mb-8'>All Artworks</h1>
      )}
      {artworkDetail || isFormView ? (
        <div className='flex items-center mb-6'>
          <button
            className='mr-4 text-xs font-normal flex'
            onClick={closeModal}>
            <span className='my-auto ml- mr-2'>
              <FaArrowLeft />
            </span>
            Go Back
          </button>

          <h1 className=''>
            <span className='text-sm font-normal'>Artwork</span> /{' '}
            <span className={viewWork ? '' : 'font-bold'}>
              {selectedWork?.title}
            </span>
            {viewWork ? <span className='font-bold '> / Artwork</span> : null}
          </h1>
        </div>
      ) : null}

      {pageChanging ? (
        <Center className='min-h-[50vh]'>
          <Loader />
        </Center>
      ) : loading ? (
        <div className='flex justify-center text-center items-center h-[70vh] my-auto'>
          <Loader size={40} />
        </div>
      ) : allArtworks.length === 0 ? (
        <>
          <div className='flex justify-center h-[80vh] my-auto '>
            <div className='my-auto text-center'>
              <p className='font-bold text-xl mb-3 mx-auto'>
                No artwork created yet.
              </p>
              <button
                onClick={createModal}
                className='px-32 bg-[#DA3400] text-white py-2 rounded-lg font-bold'>
                Add artwork
              </button>
            </div>
          </div>
        </>
      ) : isFormView ? (
        <div className='w-4/5 mt-12 mx-5 p-6 mb-20 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
          <LoadingOverlay
            visible={isLoading}
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: 'red', type: 'bars' }}
          />
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <div className='mb-4'>
              <div className='flex justify-around text-center mb-10'>
                <div className='mx-auto justify-center'>
                  <Avatar
                    size='xl'
                    src={selectedWork?.previewImage || previewImage || ''}
                    alt='preview Image Work'
                    className='mx-auto cursor-pointer'
                    onClick={() => fileInputRefPreview.current?.click()}>
                    <FaCamera />
                  </Avatar>
                  <p className='text-sm text-[#DA3400] mt-1'>
                    Upload Artist Preview Image
                  </p>
                </div>
              </div>
              <input
                ref={fileInputRefPreview}
                name='previewImage'
                id='previewImageUpload'
                type='file'
                accept='image/*'
                onChange={handlePreviewImageUpload}
                className='hidden cursor-pointer'
              />
            </div>
            <div className='grid grid-cols-2 gap-y-8  gap-x-5'>
              <TextInput
                label='Artwork Title'
                placeholder='Artwork Title'
                {...form.getInputProps('title')}
              />
              <TextInput
                label='Year of Creation'
                type='date'
                placeholder='Year of Creation'
                {...form.getInputProps('year')}
              />

              <Select
                label='Artwork Category'
                placeholder='Select categories'
                data={['single', 'mixed media']}
                {...form.getInputProps('categories')}
              />
              <TagsInput
                label='Artwork Material'
                placeholder='Enter materials'
                data={[]}
                {...form.getInputProps('materials')}
              />
              <TextInput
                label='Artwork Dimension (Length)'
                type='number'
                placeholder='Artwork Dimension (Length)'
                {...form.getInputProps('dimensionLengthInCM')}
              />
              <TextInput
                label='Artwork Dimension (Width)'
                type='number'
                placeholder='Artwork Dimension (Width)'
                {...form.getInputProps('dimensionWidthInCM')}
              />
            </div>
            <div className='mt-8'>
              <small>Additional Images</small>
              <div className='flex flex-wrap gap-5 mt-2'>
                {additionalImages.map((image, index) => (
                  <div
                    key={index}
                    className='relative shadow-lg border-[1px] px-4 py-3 rounded-md'>
                    <img
                      src={image}
                      alt={`Additional Image ${index + 1}`}
                      className='w-20 h-20 object-cover rounded-full'
                    />
                    <button
                      type='button'
                      onClick={() => removeAdditionalImage(index)}
                      className='absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1'>
                      <FaTimes size={12} />
                    </button>
                  </div>
                ))}
                <div className='shadow-lg border-[1px] px-4 py-2 rounded-md'>
                  <Avatar
                    size='lg'
                    src=''
                    alt='Upload New Image'
                    className='mx-auto cursor-pointer'
                    onClick={() => fileInputRefOthers.current?.click()}>
                    <FaPlus />
                  </Avatar>
                  <p className='text-xs text-[#DA3400] mt-1'>Add Image</p>
                </div>
              </div>
              <input
                ref={fileInputRefOthers}
                name='images'
                id='otherImageUpload'
                type='file'
                accept='image/*'
                multiple
                onChange={handleOtherImages}
                className='hidden'
              />
            </div>
            <div className='flex justify-end mt-10'>
              <button
                type='submit'
                className='px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold'>
                {isFormView ? 'Edit Artwork' : 'Create Artwork'}
              </button>
            </div>
          </form>
        </div>
      ) : artworkDetail && selectedWork ? (
        <ArtworkDetail
          edit={editModal}
          artwork={selectedWork as Work}
          selectedImage={selectedImage}
          onImageSelect={(img) => setSelectedImage(img)}
        />
      ) : (
        <div className='grid grid-cols-3 gap-10 pb-32 relative'>
          {currentArtwork.map((artwork, index) => (
            <div
              key={index}
              className='mt-1 p-4 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
              <img
                src={artwork.previewImage}
                className='w-full h-64 object-cover rounded-md'
                alt={artwork.title}
              />
              <h1 className='mt-2 text-md font-bold'>
                {artwork.artist?.firstName.toUpperCase()}{' '}
                {artwork.artist?.lastName.toUpperCase()}
              </h1>
              <h1 className='mt-2'>{artwork.title}</h1>
              <h1 className='mt-2'>{artwork.categories.join(', ')}</h1>
              <h1 className='mt-2'>{artwork.materials.join(', ')}</h1>
              <h1 className='mt-2'>
                {artwork.dimensionLengthInCM}" x {artwork.dimensionWidthInCM}"
              </h1>
              <div className='flex justify-end'>
                <button
                  onClick={() => handleViewWorks(artwork)}
                  className='rounded-md py-1 px-5 bg-[#DA3400] text-white'>
                  View
                </button>
              </div>
            </div>
          ))}

          <div className='absolute bottom-10 md:right-5 right-2 '>
            <Pagination
              total={Math.ceil(allArtworks.length / itemsPerPage)}
              value={currentPage}
              onChange={handlePageChange}
              color='#DA3400'
              radius='lg'
              className=' '
              mt={30}
              px={0}
              styles={{
                control: {
                  margin: '6px',
                },
              }}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Index;
