import { fetchData } from "./MainServices";

interface ApiStructure<T> {
  data: T;
  message?: string;
  success: boolean;
}

// login api
export async function apiLogin(payload: any) {
  return fetchData<{ data: Login }>({
    url: "/admin/login",
    method: "post",
    data: payload,
  });
}
// api change password
export async function apiChangePassword(payload: any) {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: "/admin/change-password",
    method: "post",
    data: payload,
  });
}

export const getPresignedUrl = async (name: string) => {
  return fetchData<{ url: string; fileName: string }>({
    url: `/presigned-url?type=hero&originalFileName=${name}`,
    method: "get",
  });
};

// create hero
export const apiCreateHero = async (payload: {
  title: string;
  image: string;
}) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: "/home/hero",
    method: "post",
    data: payload,
  });
};

// Get  all hero
export async function apiGetHeros() {
  return fetchData<{ heros: Hero[] }>({
    url: "/home/hero",
    method: "get",
  });
}
// overview details
export async function apiOverview() {
  return fetchData<{ data: Overview }>({
    url: "/home/overview",
    method: "get",
  });
}

// delete hero card
export async function apiDeleteHero(id: string) {
  return fetchData<ApiStructure<{ message: string; status: string }>>({
    url: `home/hero/${id}`,
    method: "delete",
  });
}

// edit hero
export async function apiEditHero(
  id: string,
  payload: { title: string; image: string }
) {
  return fetchData<ApiStructure<{ hero: Hero[] }>>({
    url: `home/hero/${id}`,
    method: "patch",
    data: payload,
  });
}

// get all artists
export async function apiGetAllArtist() {
  return fetchData<{ artists: Artist[] }>({
    url: "/artist",
    method: "get",
  });
}
// get one artists
export async function apiSingleArtist(id: string) {
  return fetchData<{ artist: Artist }>({
    url: `/artist/${id}`,
    method: "get",
  });
}

// create artist
export const apiCreateArtist = async (payload: any, id?: string) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: "/artist",
    method: "post",
    data: payload,
  });
};

// delete artist
export async function apiDeleteArtist(id: string) {
  return fetchData<ApiStructure<{ message: string; status: string }>>({
    url: `/artist/${id}`,
    method: "delete",
  });
}
// edit profile
export async function apiProfile(id: string, payload: any) {
  return fetchData<ApiStructure<{ updatedArtist: Artist }>>({
    url: `/artist/${id}`,
    method: "patch",
    data: payload,
  });
}

// create artwork
export const apiArtwork = async (payload: any, id: string) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: `/work/${id}`,
    method: "post",
    data: payload,
  });
};

// edit artwork of an artist
export const apiEditArtwork = async (_id: string, payload: any) => {
  return fetchData<{updatedWork: Work }>({
    url: `/work/${_id}`,
    method: "patch",
    data: payload,
  });
};

// delete artwork
export async function apiDeleteArtwork(id: string) {
  return fetchData<ApiStructure<{ message: string; status: string }>>({
    url: `/work/${id}`,
    method: "delete",
  });
}

// update featured artwork
export const apiUpdateFeaturedArtwork = async (payload: any) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: "/home/featured-artwork",
    method: "patch",
    data: payload,
  });
};

// get featured artwork
export const apiGetFeaturedArtwork = async () => {
  return fetchData<{ featuredArtworks: Work[] }>({
    url: "/home/featured-artwork",
    method: "get",
  });
};
// get all artwork
export const apiGetAllArtwork = async () => {
  return fetchData<{ works: Work[] }>({
    url: "/work",
    method: "get",
  });
};

// get our works
export const apiGetOurWorks = async () => {
  return fetchData<{ ourWorks: Work[] }>({
    url: "/home/our-work",
    method: "get",
  });
};

// update ourwork
export const apiUpdateOurWork = async (payload: any) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: "/home/our-work",
    method: "patch",
    data: payload,
  });
};

// create exhibition
export const apiCreateExhb = async (payload: any) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: "/exhibition",
    method: "post",
    data: payload,
  });
};

// get all exhb
export const apiAllGetExhb = async () => {
  return fetchData<{ exhibitions: Exhibition[] }>({
    url: "/exhibition",
    method: "get",
  });
};
// get featured exhb
export const apiFeatExhb = async () => {
  return fetchData<{ featuredExhibitions: Exhibition[] }>({
    url: "/home/featured-exhibition",
    method: "get",
  });
};

// update featured exhb
export const apiUpdateExhb = async (payload: any) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: "/home/featured-exhibition",
    method: "patch",
    data: payload,
  });
};

// update one artist exhb
export const apiArtistExhb = async (id: string, payload: any) => {
  return fetchData<{
    updatedExhibition: Exhibition;
  }>({
    url: `/exhibition/${id}`,
    method: "patch",
    data: payload,
  });
};
// delete exhb
export const apiDeleteExhb = async (id: string) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: `/exhibition/${id}`,
    method: "delete",
  });
};

// create admin
export const apiCreateAdmin = async (payload: any) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: "/admin",
    method: "post",
    data: payload,
  });
};
// delete admin
export const apiDeleteAdmin = async (id: string) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: `/admin/${id}`,
    method: "delete",
  });
};
// updat sub admin
export const apiUpdateAdmin = async (payload: any, id: string) => {
  return fetchData<ApiStructure<{ success: boolean; message: string }>>({
    url: `admin/update-admin/${id}`,
    method: "patch",
    data: payload,
  });
};

// update super amdmin api
export async function apiUpdateLoginAdmin(payload: any) {
  return fetchData<{ data: Login }>({
    url: "/admin",
    method: "patch",
    data: payload,
  });
}

// get all admin
export const apiAllAdimns = async () => {
  return fetchData<{ admins: Admin[] }>({
    url: "/admin",
    method: "get",
  });
};
