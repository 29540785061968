import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Loader,
  LoadingOverlay,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import {
  apiArtistExhb,
  apiCreateExhb,
  getPresignedUrl,
} from "../../../api/ApiServices";
import dayjs from "dayjs";
import { FaArrowLeft, FaCamera, FaPlus } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import ExhbDetails from "./ExhbDetails";
interface ExhibitionsProps {
  artistExhb: Exhibition[];
  fetchExhb: any;
}
const ArtistExhibitions: React.FC<ExhibitionsProps> = ({
  artistExhb,
  fetchExhb,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isFormView, setIsFormView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentExhbPage, setCurrentExhbPage] = useState(1);
  const [upcomingExhbPage, setUpcomingExhbPage] = useState(1);
  const itemsPerPage = 1;
  const [editingExhb, setEditingExhb] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [isExhbDetails, setIsExhbDetails] = useState(false);
  const [selectedExhibition, setSelectedExhibition] = useState<
    Exhibition | null | undefined
  >(null);

  const createModal = () => {
    setIsFormView(true);
  };
  const editModal = () => {
    setIsFormView(true);
    setEditingExhb(true);
  };

  const filteredExhbByStatus = (status: "current" | "upcoming" | "past") => {
    const currentDate = new Date();
    return (
      (artistExhb &&
        artistExhb?.filter((exhibition) => {
          const startDate = new Date(exhibition.startDate);
          const endDate = new Date(exhibition.endDate);
          if (status === "current") {
            return startDate <= currentDate && endDate >= currentDate;
          } else if (status === "upcoming") {
            return startDate > currentDate;
          }
          return endDate < currentDate;
        })) ||
      []
    );
  };

  const RenderExhibitions = ({
    status,
  }: {
    status: "current" | "upcoming";
  }) => {
    const exhibitions = filteredExhbByStatus(status);
    const currentPage =
      status === "current" ? currentExhbPage : upcomingExhbPage;
    const setPage =
      status === "current" ? setCurrentExhbPage : setUpcomingExhbPage;

    return (
      <div key={status} className="md:p-0 p-3 md:mt-20 mt-12">
        <h1 className="font-medium mb-2">{status.toUpperCase()} EXHIBITIONS</h1>
        <div className="md:space-y-0 ">
          {exhibitions.length > 0 ? (
            exhibitions
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((exhibition) => (
                <div
                  key={exhibition._id}
                  className="md:grid grid-cols-3 gap-10 relative">
                  <div>
                    <img
                      className="md:w-[450px] md:h-[350px] w-[390px] h-[280px]"
                      src={exhibition.previewImageExhibition}
                      alt={exhibition.title}
                      loading="lazy"
                    />
                  </div>
                  <div className="font-medium mt-2 md:my-auto mb-16">
                    <h1 className="mt-1 mb-2 font-semibold text-xl">
                      {exhibition.artists.map((artist, index) => (
                        <span key={index}>
                          {artist?.firstName?.toLocaleUpperCase()}{" "}
                          {artist?.lastName?.toLocaleUpperCase()}
                          {index < exhibition.artists.length - 1 ? " x " : ""}
                        </span>
                      ))}
                    </h1>
                    <h1 className=" font-semibold">
                      {exhibition.title.toUpperCase()}
                    </h1>
                    <p className="">{exhibition.type}</p>
                    <p className="my-3">
                      {dayjs(exhibition.startDate).format("D MMM")} -{" "}
                      {dayjs(exhibition.endDate).format("D MMM, YYYY")}
                    </p>
                    <div className="absolute md:bottom-0 -bottom-20">
                      <button
                        onClick={() => viewExhbDetails(exhibition)}
                        // onClick={() => handleViewWorks(artwork)}
                        className="rounded-md py-1 px-5 bg-[#DA3400] text-white">
                        View
                      </button>
                      {/* <Pagination
                      total={
                        status === 'current'
                          ? totalCurrentExhb
                          : totalUpcomingExhb
                      }
                      value={currentPage}
                      color='#DA3400'
                      radius='lg'
                      className=' '
                      mt={30}
                      px={0}
                      styles={{
                        control: {
                          margin: '6px',
                        },
                      }}
                      onChange={setPage}
                    /> */}
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <p className="text-[##DA3400]">
              No {status} exhibitions available.
            </p>
          )}
        </div>
      </div>
    );
  };
  const form = useForm({
    initialValues: {
      artistId: "",
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      type: "",
      subTitle: "",
    },
    validate: {
      artistId: (value) => (value.trim() ? null : "Artist is required"),
      title: (value) => (value.trim() ? null : "Exhibition Title is required"),
      startDate: (value) => (value.trim() ? null : "Start Date is required"),
      endDate: (value) => (value.trim() ? null : "End Date is required"),
      description: (value) => (value ? null : "Description is required"),
      type: (value) =>
        value.length > 0 ? null : "At least one category is required",
      subTitle: (value) => {
        if (!value || value.trim() === "") {
          return null;
        }
      },
    },
  });
  useEffect(() => {
    if (selectedExhibition) {
      form.setValues({
        artistId:
          selectedExhibition?.artists
            .map(
              (artist, index) =>
                `${artist?.firstName?.toLocaleUpperCase()} ${artist?.lastName?.toLocaleUpperCase()}${index < selectedExhibition.artists.length - 1 ? " x " : ""
                }`
            )
            .join("") || "",
        title: selectedExhibition?.title || "",
        startDate: selectedExhibition?.startDate
          ? dayjs(selectedExhibition?.startDate).format("YYYY-MM-DD")
          : "",
        endDate: selectedExhibition?.endDate
          ? dayjs(selectedExhibition?.endDate).format("YYYY-MM-DD")
          : "",
        description: selectedExhibition?.description || "",
        type: selectedExhibition?.type || "",
      });

      if (selectedExhibition.previewImageExhibition) {
        setPreviewImage(selectedExhibition.previewImageExhibition);
        setFiles((prev) => ({
          ...prev,
          preview: selectedExhibition.previewImageExhibition,
        }));
      }

      // Set the additional images
      if (selectedExhibition.images && selectedExhibition.images.length > 0) {
        setAdditionalImages(selectedExhibition.images);
        setFiles((prev) => ({
          ...prev,
          additional: selectedExhibition.images,
        }));
      } else {
        setAdditionalImages([]);
      }
    }
  }, [selectedExhibition]);

  const fileInputRefPreview = useRef<HTMLInputElement>(null);
  const fileInputRefOthers = useRef<HTMLInputElement>(null);

  const handlePreviewImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === "string") {
          setPreviewImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, "preview");
    }
  };

  const handleOtherImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      for (const file of Array.from(files)) {
        try {
          await uploadImage(file, "additional");
        } catch (error) {
          console.error("Error uploading image:", error);
          notifications.show({
            title: "Upload Error",
            color: "red",
            message: "An error occurred while uploading the image.",
          });
        }
      }
    }
  };

  const removeAdditionalImage = (index: number) => {
    setAdditionalImages((prev) => prev.filter((_, i) => i !== index));
    setFiles((prev) => ({
      ...prev,
      additional: prev.additional.filter((_, i) => i !== index),
    }));
  };

  const [files, setFiles] = useState<{
    preview: string;
    additional: string[];
  }>({
    preview: selectedExhibition?.previewImage || "",
    additional: selectedExhibition?.images || [],
  });

  const uploadImage = async (file: File, type: "preview" | "additional") => {
    setIsLoading(true);
    try {
      const resp = await getPresignedUrl(file.name);
      const uploadResponse = await fetch(resp.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      if (!uploadResponse.ok) {
        notifications.show({
          title: "Something went wrong",
          color: "red",
          mt: "100px",
          message: "An error occurred, please try again later.",
        });
        return;
      }
      if (type === "preview") {
        setFiles((prev) => ({
          ...prev,
          preview: resp.fileName,
        }));
        setPreviewImage(URL.createObjectURL(file));
      } else {
        setFiles((prev) => ({
          ...prev,
          additional: [...prev.additional, resp.fileName],
        }));
        setAdditionalImages((prev) => [...prev, URL.createObjectURL(file)]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsFormView(false);
    form.reset();
    setEditingExhb(false);
    setPreviewImage(null);
    setAdditionalImages([]);
    setIsExhbDetails(false);

    form.reset();
    setFiles({ preview: "", additional: [] });
  };

  const handleSubmit = async () => {
    console.log('first')
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      console.log("Form has errors", validationResult.errors);
      return;
    }

    if (!files.preview || files.additional.length === 0) {
      notifications.show({
        title: "Missing Images",
        color: "red",
        mt: "100px",
        message: "Please upload both preview and additional images.",
      });
      return;
    }

    setLoading(true);
    try {
      const payload: any = {
        ...form.values,
        artistIds: artistExhb.map((exhibition) => exhibition._id),
        previewImageExhibition: files.preview,
        images: files.additional,
      };

      if (editingExhb && selectedExhibition) {
        // Editing existing exhibition
        const originalImages = selectedExhibition.images || [];
        payload._id = selectedExhibition._id;
        payload.addedImages = files.additional.filter(
          (img) => !originalImages.includes(img)
        );
        payload.removedImages = originalImages.filter(
          (img) => !files.additional.includes(img)
        );
        const response = await apiArtistExhb(selectedExhibition._id, payload);
        setSelectedExhibition(response.updatedExhibition);
        console.log(response, "editing exhibition");
      } else {
        const response = await apiCreateExhb(payload as any);
        console.log(response, "creating hero");
      }
      setLoading(false);
      notifications.show({
        title: editingExhb ? "Exhibition Edited" : "Exhibition Created",
        color: "green",
        mt: "100px",
        message: editingExhb
          ? "Exhibition successfully Edited"
          : "Exhibition Successfully Created ",
      });
      closeModal();
      fetchExhb()
    } catch (error: any) {
      notifications.show({
        title: "Something went wrong",
        color: "red",
        mt: "100px",
        message: error?.message || "An error occurred, please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const viewExhbDetails = (exhibition: Exhibition) => {
    setSelectedExhibition(exhibition);
    setIsExhbDetails(true);
  };

  return (
    <section className="">
      <div className="flex justify-between items-center mb-6 relative">
        <div className="flex items-center ">
          <button
            className="mr-4 text-xs font-normal flex"
            onClick={closeModal}>
            <span className="my-auto ml- mr-2">
              <FaArrowLeft />
            </span>
            Go Back
          </button>

          {/* <h1 className='font-semibold'>
            <span className='text-sm font-normal'>Artist </span> /{' '}
             {artistExhb.map((exhibition) => 
    exhibition.artists.map((artist, artistIndex) => 
      `${artist.firstName?.toLocaleUpperCase()} ${artist.lastName?.toLocaleUpperCase()}${artistIndex < exhibition.artists.length - 1 ? " & " : ""}`
    ).join('')
  ).join(' x ')}
          </h1> */}
        </div>

        {isExhbDetails ? null : (
          <button
            className="rounded-md py-2 px-5 bg-[#DA3400] text-white"
            onClick={() => createModal()}>
            Add Exhibition
          </button>
        )}
      </div>
      {isFormView ? (
        <div className="w-4/5 mt-12 mx-5 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]">
          <LoadingOverlay
            visible={isLoading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
            loaderProps={{ color: "red", type: "bars" }}
          />
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <div className="mb-4">
              <div className="flex justify-around text-center mb-10">
                <div className="mx-auto justify-center">
                  <Avatar
                    size="lg"
                    src={previewImage || ""}
                    alt="preview Image Work"
                    className="mx-auto cursor-pointer"
                    onClick={() => fileInputRefPreview.current?.click()}>
                    <FaCamera />
                  </Avatar>
                  <p className="text-sm text-[#DA3400] mt-1">
                    Upload Preview Image
                  </p>
                </div>
              </div>
              <input
                ref={fileInputRefPreview}
                name="previewImage"
                id="previewImageUpload"
                type="file"
                accept="image/*"
                onChange={handlePreviewImageUpload}
                className="hidden cursor-pointer"
              />
            </div>
            <div className="grid grid-cols-2 gap-y-8  gap-x-5">
              <TextInput
                label="Artist Name (Auto Filled)"
                disabled={isExhbDetails}
                {...form.getInputProps("artistId")}
              />

              <TextInput
                label="Exhibition Start Date"
                type="date"
                {...form.getInputProps("startDate")}
              />
              <TextInput
                label="Exhibition End Date"
                type="date"
                {...form.getInputProps("endDate")}
              />
              <TextInput
                label="Exhibition Title"
                placeholder="Exhibition Title"
                {...form.getInputProps("title")}
              />

              <Textarea
                minRows={6}
                autosize
                label="About Exhibition"
                placeholder="About Exhibition..."
                {...form.getInputProps("description")}
              />
              <div className="flex flex-col space-y-8">
                <Select
                  label="Artwork Category"
                  placeholder="Select categories"
                  data={["solo", "collaborative"]}
                  {...form.getInputProps("type")}
                />
                <TextInput
                  label="Sub Title (Optional)"
                  placeholder="Sub Title "
                  {...form.getInputProps("subTitle")}
                />
              </div>
            </div>
            <div className="mt-8">
              <small>Exhibition Image</small>
              <div className="flex flex-wrap gap-5 mt-2">
                {additionalImages.map((image, index) => (
                  <div
                    key={index}
                    className="relative shadow-lg border-[1px] px-4 py-2 rounded-md">
                    <img
                      src={image}
                      alt={`Additional Image ${index + 1}`}
                      className="w-20 h-20 object-cover rounded-full"
                    />
                    <button
                      type="button"
                      onClick={() => removeAdditionalImage(index)}
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1">
                      <FaTimes size={12} />
                    </button>
                    {/* <p className='text-xs text-[#DA3400] mt-1 text-center'>
                      Change Image
                    </p> */}
                  </div>
                ))}
                <div className="shadow-lg border-[1px] px-4 py-2 rounded-md">
                  <Avatar
                    size="lg"
                    src=""
                    alt="Upload New Image"
                    className="mx-auto cursor-pointer"
                    onClick={() => fileInputRefOthers.current?.click()}>
                    <FaPlus />
                  </Avatar>
                  <p className="text-xs text-[#DA3400] mt-1">Add Image</p>
                </div>
              </div>
              <input
                ref={fileInputRefOthers}
                name="images"
                id="otherImageUpload"
                type="file"
                accept="image/*"
                multiple
                onChange={handleOtherImages}
                className="hidden"
              />
            </div>
            <div className="flex justify-end mt-16">
              <button
                type="submit"
                className='px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold 
                  cursor-pointer'
              >
                {editingExhb ? "Save Exhibition" : "Create Exhibition"}
              </button>
            </div>
          </form>
        </div>
      ) : isExhbDetails && selectedExhibition ? (
        <ExhbDetails
          exhibition={selectedExhibition}
          close={closeModal}
          edit={editModal}
          reload={fetchExhb}
        />
      ) : (
        <div className="cursor-pointer">
          <RenderExhibitions status="current" />
          <RenderExhibitions status="upcoming" />
          <div className="md:p-0 p-3 md:mt-16 mt-12">
            <h1 className="font-medium">PAST EXHIBITIONS</h1>
            <div className="grid md:grid-cols-3 gap-8 mt-5 space-y-10 md:space-y-0 ">
              {filteredExhbByStatus("past").length > 0 ? (
                filteredExhbByStatus("past").map((exhibition) => (
                  <div
                    key={exhibition._id}
                    className="md:grid gap-3 bg-white shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] rounded-lg  p-4 ">
                    <img
                      className="md:w-[450px] md:h-[300px] w-[390px] h-[280px] rounded-lg"
                      src={exhibition.previewImageExhibition}
                      alt={exhibition.title}
                      loading="lazy"
                    />
                    <div className="font-medium mt-2 md:mt-0">
                      <h1 className="mt-1 mb-2 font-semibold ">
                        {exhibition.artists.map((artist, index) => (
                          <span key={index}>
                            {artist?.firstName?.toLocaleUpperCase()}{" "}
                            {artist?.lastName?.toLocaleUpperCase()}
                            {index < exhibition.artists.length - 1 ? " x " : ""}
                          </span>
                        ))}
                      </h1>
                      <h1 className="text-sm">
                        {exhibition.title.toUpperCase()}
                      </h1>
                      <p className="my-2">{exhibition.type}</p>
                      <p className="mt-2 mb-4">
                        {dayjs(exhibition.startDate).format("D MMM")} -{" "}
                        {dayjs(exhibition.endDate).format("D MMM, YYYY")}
                      </p>
                      <button
                        onClick={() => viewExhbDetails(exhibition)}
                        //   onClick={() => viewExhbDetails(exhibition._id)}
                        className="rounded-md py-1 px-5 bg-[#DA3400] text-white">
                        View
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-[##DA3400]">
                  No past exhibitions available.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ArtistExhibitions;
