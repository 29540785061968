import { useEffect, useState } from 'react';
import style from '../../../styles/style.module.css';
import { FaArrowLeft } from 'react-icons/fa6';
import {
  Table,
  Avatar,
  TextInput,
  Checkbox,
  LoadingOverlay,
  Loader,
} from '@mantine/core';
import dayjs from 'dayjs';
import { FaTimes } from 'react-icons/fa';
import {
  apiAllGetExhb,
  apiFeatExhb,
  apiUpdateExhb,
} from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';

const FeaturedExhb = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTableView, setIsTableView] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [featuredExb, setFeaturedExb] = useState<string[]>([]);
  const [replacingArtworkId, setReplacingArtworkId] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [allExhb, setAllExhb] = useState<Exhibition[]>([]);
  const [featExhb, setFeatExhb] = useState<Exhibition[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredExhb, setFilteredExhb] = useState<Exhibition[]>([]);
  const [currentFeaturedExhb, setCurrentFeaturedExhb] = useState<string[]>([]);
  const allArtwork = async () => {
    setLoading(true);
    try {
      const resp = await apiAllGetExhb();
      setAllExhb(resp.exhibitions);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
      setLoading(false);
    }
  };

  const getFeaturedExhb = async () => {
    setLoading(true);
    try {
      const resp = await apiFeatExhb();
      setFeatExhb(resp.featuredExhibitions);
      setCurrentFeaturedExhb(resp.featuredExhibitions.map((exhb) => exhb._id));

      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    allArtwork();
    getFeaturedExhb();
    setFeaturedExb(featExhb.slice(0, 3).map((item) => item._id));
  }, []);

  const handleTableClick = () => {
    setIsTableView(true);
  };
  const handleGoBack = () => {
    setIsTableView(false);
  };

  const handleReplace = (id: string) => {
    setReplacingArtworkId(id);
    setSelectedRows([id]);
    setIsTableView(true);
  };

  const handleReplaceAll = () => {
    setReplacingArtworkId(null);
    setSelectedRows(featExhb.map((artwork) => artwork._id));
    setIsTableView(true);
  };

  const handleRowSelection = (id: string) => {
    setSelectedRows((prev) => {
      if (prev.includes(id)) {
        // If the id is already selected, remove it
        return prev.filter((rowId) => rowId !== id);
      } else {
        // If the id is not selected, add it (up to the limit)
        const limit = replacingArtworkId ? 1 : 3;
        return [...prev, id].slice(0, limit);
      }
    });
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let payload;
      if (featExhb.length === 0) {
        // If there are no existing our artworks
        payload = {
          addedFeaturedExhibitionIds: selectedRows,
        };
      } else if (replacingArtworkId) {
        // If replacing a single artwork
        payload = {
          addedFeaturedExhibitionIds: selectedRows,
          removedFeaturedExhibitionIds: [replacingArtworkId],
        };
      } else {
        // If replacing all ourwork
        payload = {
          addedFeaturedExhibitionIds: selectedRows,
          removedFeaturedExhibitionIds: featExhb.map((artwork) => artwork._id),
        };
      }
      const resp = await apiUpdateExhb(payload);
      getFeaturedExhb();
      setSelectedRows([]);
      setCurrentFeaturedExhb(selectedRows); 
      setIsTableView(false);
      notifications.show({
        title: 'Success',
        color: 'green',
        mt: '100px',
        message: 'Exhibitions updated successfully.',
      });
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderSelectedCards = () => {
    return (
      <div className='flex gap-4 mb-8'>
        {selectedRows.map((id) => {
          const item = allExhb.find((d) => d._id === id);
          if (!item) return null;
          return (
            <div
              key={id}
              className='relative w-48 h-68 p-2 bg-white shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] rounded-lg overflow-hidden'>
              <img
                src={item.previewImageExhibition}
                alt={item.title}
                className='w-full h-36 object-cover rounded-sm'
              />
              <div className='p-2'>
                <h3 className='font-bold text-sm'>
                  {item.artists[0]?.firstName} {item.artists[0]?.lastName}
                </h3>
                <p className='text-xs my-1'>{item.title}</p>
                <p className='text-xs'>
                  {dayjs(item.startDate).format('D MMM')} -
                  {dayjs(item.endDate).format('D MMM YYYY')}
                </p>
              </div>
              <button
                onClick={() => handleRowSelection(id)}
                className='absolute top-1 right-1 bg-white rounded-full p-1'>
                <FaTimes className='text-red-500' />
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    let filtered = allExhb;

    const lowercaseQuery = searchQuery.toLowerCase().trim();
    filtered = filtered.filter(
      (artist) =>
        artist.artists[0]?.firstName.toLowerCase().includes(lowercaseQuery) ||
        artist.artists[0]?.lastName.toLowerCase().includes(lowercaseQuery)
    );
    setFilteredExhb(filtered);
  }, [searchQuery, allExhb]);

  const renderTable = () => {
    const rows = filteredExhb.map((element) => {
      const isFeatured = currentFeaturedExhb.includes(element._id);
      return (
        <Table.Tr
          key={element._id}
          className={`cursor-pointer 
         ${isFeatured ? 'bg-[#f1f1f1]' : ''}`}>
          <Table.Td>
            <Checkbox
              color='#DA3400'
              aria-label='Select row'
              checked={selectedRows.includes(element._id)}
              onChange={() => handleRowSelection(element._id)}
            />
          </Table.Td>
          <Table.Td>
            <Avatar
              src={element.previewImageExhibition}
              alt='preview Image Work'
            />
          </Table.Td>
          <Table.Td>
            {element.artists[0].firstName} {element.artists[0].lastName}
          </Table.Td>
          <Table.Td>{element.title}</Table.Td>
          <Table.Td>{element?.subTitle}</Table.Td>
          <Table.Td>
            {dayjs(element.startDate).format('D MMM')} -
            {dayjs(element.endDate).format('D MMM YYYY')}
          </Table.Td>
        </Table.Tr>
      );
    });

    return (
      <section className='border-[1px] shadow-lg p-3 rounded-lg'>
        <div className='flex justify-between mt-4 mb-8'>
          <p className='my-auto'>
            {replacingArtworkId && replacingArtworkId?.length >= 1
              ? 'Select One Artwork'
              : 'Select Three Artworks'}
          </p>
          <div className='flex gap-6'>
            <TextInput
              placeholder='Search by name'
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
            />
            <button className='bg-[#DA3400] text-white px-10 rounded-lg font-bold'>
              Search
            </button>
          </div>
        </div>
        <Table highlightOnHover>
          <Table.Thead bg='#EFF1F0' py='xl'>
            <Table.Tr>
              <Table.Th />
              <Table.Th />
              <Table.Th className='py-4'>Artist Name</Table.Th>
              <Table.Th>Exhibition Title</Table.Th>
              <Table.Th>Exhibition Sub Title</Table.Th>
              <Table.Th>Exhibition Date</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {filteredExhb.length === 0 ? (
              <Table.Tr>
                <Table.Td colSpan={10} style={{ height: '200px' }}>
                  <div className='flex justify-center items-center h-full'>
                    <p className='text-lg font-semibold text-center'>
                      No artists found.
                    </p>
                  </div>
                </Table.Td>
              </Table.Tr>
            ) : (
              rows
            )}
          </Table.Tbody>
        </Table>
      </section>
    );
  };

  return (
    <section className={style.main}>
      {isTableView ? null : (
        <h1 className='font-bold text-lg mt-3 mb-6'>Featured Exhibition</h1>
      )}
      {isTableView ? (
        <div className='relative'>
          <LoadingOverlay
            visible={isLoading}
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: 'red', type: 'bars' }}
          />
          <div className='flex items-center mb-4 mt-3'>
            <button
              className='mr-4 text-xs font-normal flex'
              onClick={handleGoBack}>
              <span className='my-auto ml- mr-2'>
                <FaArrowLeft />
              </span>
              Go Back
            </button>
            <h1 className='font-bold text-lg'>
              <span className='text-sm font-normal'>
                Featured Exhibitions /{' '}
              </span>
              {selectedRows.length > 0
                ? 'Replace Exhibitions'
                : 'Add Exhibitions'}
            </h1>
          </div>

          <div className=' mt-8 mb-20'>
            {renderSelectedCards()}
            {renderTable()}
            <button
              onClick={handleSubmit}
              disabled={isLoading || selectedRows.length === 0}
              className='bg-[#DA3400] text-white py-2 px-12 rounded-lg font-bold mt-10'>
              {isLoading ? 'Uploading...' : 'Upload Exhibitions'}
            </button>
          </div>
        </div>
      ) : loading ? (
        <div className='flex justify-center text-center items-center h-[70vh] my-auto'>
          <Loader size={40} />
        </div>
      ) : featExhb.length > 0 ? (
        <>
          <div className='grid grid-cols-3 gap-10 mb-12'>
            {featExhb.map((item, _id) => (
              <div
                key={_id}
                className='mt-1 p-4 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
                <img
                  src={item.previewImageExhibition}
                  className='w-full h-64 object-cover rounded-md'
                  alt={item.title}
                />
                <div className='mt-2'>
                  <h3 className='font-bold text-lg'>
                    {item?.artists[0]?.firstName} {item?.artists[0]?.lastName}
                  </h3>
                  <p className=' my-1'>{item.title}</p>
                  <p className=''>
                    {dayjs(item.startDate).format('D MMM')} -
                    {dayjs(item.endDate).format('D MMM YYYY')}{' '}
                  </p>
                </div>
                <div className='flex justify-end'>
                  <button
                    onClick={() => handleReplace(item._id)}
                    className='rounded-md py-1 px-5 bg-[#DA3400] text-white'>
                    Replace
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className='mb-20'>
            <button
              onClick={handleReplaceAll}
              className='rounded-md py-2 px-16 bg-[#DA3400] text-white'>
              Replace All Featured Exhibitions
            </button>
          </div>
        </>
      ) : (
        <>
          <div className='flex justify-center h-[70vh] my-auto '>
            <div className='my-auto text-center'>
              <p className='font-bold text-xl mb-3'>
                No artwork added to the featured exhibitions section yet.
              </p>
              <button
                onClick={handleTableClick}
                className='w-10/12 bg-[#DA3400] text-white py-3 rounded-lg font-bold'>
                Add Three Exhibitions
              </button>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default FeaturedExhb;
