import { Loader, Divider } from '@mantine/core';
import dayjs from 'dayjs';
import { useState } from 'react';
import { apiDeleteExhb } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ExhbDetailProps {
  exhibition: Exhibition;
  close: () => void;
  edit: () => void;
  reload: () => Promise<void>;
}

const ExhbDetail = ({ exhibition, close, edit, reload }: ExhbDetailProps) => {
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3500,
    infinite: true,
    navigator: false,
    speed: 4500,
    cssEase: 'linear',
    initialSlide: 0,
  };

  const [loading, setLoading] = useState<boolean>(false);

  if (!exhibition) {
    return null;
  }

  const deleteArtwork = async (id: string) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this Exhibition?'
    );
    if (!isConfirmed) {
      notifications.show({
        title: 'Cancel',
        mt: '100px',
        color: 'blue',
        message: 'You have successfully Cancel the action to delete  ',
      });
      return;
    }
    try {
      const resp = await apiDeleteExhb(id);
      notifications.show({
        title: 'Successful',
        mt: '100px',
        color: 'green',
        message: 'You have successfully deleted the Exhibition ',
      });
      close();
      reload();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className='relative p-6 mt-5 mb-12 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
      {loading ? (
        <div className='flex justify-center text-center items-center h-[80vh] my-auto'>
          <Loader size={40} />
        </div>
      ) : (
        <div>
          <div className='md:grid grid-cols-2 space-x-8 my-10 '>
            <div className=''>
              <h1 className='font-bold mt-2 text-xl'>{exhibition.title}</h1>
              <h3 className='mt-1 mb-2'>{exhibition.subTitle}</h3>
              <h3>
                {exhibition.artists.map((artist, index) => (
                  <span key={artist._id} className='mt-1 mb-2'>
                    {artist?.firstName} {artist?.lastName}
                    {index < exhibition.artists.length - 1 ? ' x ' : ''}
                  </span>
                ))}{' '}
              </h3>
              <Divider size='xs' color='#ADADAD' />
              <p className='my-2'>
                {dayjs(exhibition.startDate).format('D MMM')} -{' '}
                {dayjs(exhibition.endDate).format('D MMM, YYYY')}
              </p>
              <p className='mt-8 mb-32'>{exhibition.description}</p>
              <div className='absolute bottom-16 flex gap-x-5 mt-5'>
                <button
                  className='px-8 bg-[#DA3400] text-white py-2 rounded-md font-bold'
                  onClick={edit}>
                  Edit Exhibition
                </button>
                <button
                  className='rounded-md px-8 bg-transparent border border-[#DA3400] text-[#DA3400]'
                  onClick={() => deleteArtwork(exhibition._id)}>
                  Delete Exhibition
                </button>
              </div>
            </div>

            {exhibition.images.length > 0 && (
              <div>
                <Slider {...sliderSettings}>
                  {[
                    exhibition.previewImageExhibition,
                    ...exhibition.images,
                  ].map((image, index) => (
                    <div key={index}>
                      <img
                        className='w-full h-[650px] rounded-md'
                        src={image}
                        alt={`Exhibition by ${exhibition?.artists[0]?.firstName} ${exhibition?.artists[0]?.lastName}`}
                        loading='lazy'
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};
export default ExhbDetail;
