import axios, { AxiosError, AxiosRequestConfig } from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to handle logout
const handleLogout = () => {
  localStorage.removeItem('token');
  window.location.href = '/login';
};

// 401

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// Add response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);

export async function fetchData<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response = await axiosInstance.request(config);
    return response.data;
  } catch (error: unknown) {
    const err = error as AxiosError;
    throw err.response?.data || err.response || err;
  }
}

export default axiosInstance;
