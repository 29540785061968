import {
  LoadingOverlay,
  Avatar,
  TextInput,
  Textarea,
  Radio,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useRef, useState } from 'react';
import { FaArrowLeft, FaCamera } from 'react-icons/fa6';
import { apiProfile, getPresignedUrl } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

interface ProfileProps {
  profile: Artist;
}
const Profile = ({ profile }: ProfileProps) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImageWork, setPreviewImageWork] = useState<string | null>(
    profile?.previewImageWork || null
  );

  const [files, setFiles] = useState<{ preview: string }>({
    preview: profile?.previewImageWork || '',
  });

  const fileInputRefPreview = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      dob: '',
      fullBiography: '',
      isSigned: false,
    },
    validate: {
      firstName: (value) => (value.trim() ? null : 'First name is required'),
      lastName: (value) => (value.trim() ? null : 'Last name is required'),
      dob: (value) => (value ? null : 'Date of birth is required'),
      fullBiography: (value) =>
        value.trim().length > 50
          ? null
          : 'Biography must be at least 50 characters long',
      isSigned: (value) =>
        value !== null ? null : 'Artist status is required',
    },
  });

  useEffect(() => {
    if (profile) {
      form.setValues({
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        middleName: profile.middleName || '',
        dob: profile.dob || '',
        fullBiography: profile.fullBiography || '',
        isSigned: profile.isSigned || false,
      });
      setPreviewImageWork(profile.previewImageWork || null);
      setFiles({ preview: profile.previewImageWork || '' });
    }
  }, [profile]);

  const handlePreviewImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setPreviewImageWork(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'preview');
    }
  };

  const uploadImage = async (file: File, type: 'preview') => {
    setIsLoading(true);

    try {
      const resp = await getPresignedUrl(file.name);
      const uploadResponse = await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      if (!uploadResponse.ok) {
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          mt: '100px',
          message: 'An error occurred, please try again later.',
        });
        return;
      }
      setFiles((prev) => ({
        ...prev,
        [type]: resp.fileName,
      }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleSubmit = async (id: string) => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      console.log('Form has errors', validationResult.errors);
      return;
    }

    // if (profile?.previewImageWork || !files.preview) {
    //   notifications.show({
    //     title: 'Missing Images',
    //     color: 'red',
    //     mt: '100px',
    //     message: 'Please upload both preview image.',
    //   });
    //   return;
    // }

    setLoading(true);
    try {
      const payload = {
        ...form.values,
        previewImageWork: files.preview,
      };

      const response = await apiProfile(id, payload as any);
      console.log(response, 'creating hero');

      setLoading(false);
      notifications.show({
        title: 'Artist Edited',
        color: 'green',
        mt: '100px',
        message: 'Artist Successfully Edited ',
      });
      window.history.back();
      navigate('/dashboard/artists');
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <section>
      <>
        <div className='flex items-center mb-4 mt-3'>
          <button
            onClick={() => navigate(-1)}
            className='mr-4 text-sm font-normal flex'>
            <span className='my-auto ml-6 mr-2'>
              <FaArrowLeft />
            </span>
            Go Back
          </button>
          <h1 className='font-bold text-lg'>
            <span className='text-sm font-normal'>Artist</span> / Edit Artist
          </h1>
        </div>
        <div className='w-4/5 mx-auto mt-12 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] relative'>
          <form onSubmit={form.onSubmit(() => handleSubmit(profile._id))}>
            <LoadingOverlay
              visible={isLoading}
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: 'red', type: 'bars' }}
            />
            <div className='mb-4'>
              <div className='flex justify-around text-center mb-10'>
                <div className='mx-auto justify-center'>
                  <Avatar
                    size='lg'
                    src={previewImageWork || profile?.previewImageWork || ''}
                    alt='preview Image Work'
                    className='mx-auto cursor-pointer w-32 h-32'
                    onClick={() => fileInputRefPreview.current?.click()}>
                    <FaCamera />
                  </Avatar>
                  <p className='text-sm text-[#DA3400] mt-1'>
                    Change Artist Preview Image
                  </p>
                </div>
              </div>
              <input
                ref={fileInputRefPreview}
                name='previewImageWork'
                id='previewImageUpload'
                type='file'
                accept='image/*'
                onChange={handlePreviewImageUpload}
                className='hidden'
              />
            </div>
            <div className='grid grid-cols-2 gap-5'>
              <TextInput
                label='First Name'
                placeholder='First Name'
                {...form.getInputProps('firstName')}
              />
              <TextInput
                label='Last Name'
                placeholder='Last Name'
                {...form.getInputProps('lastName')}
              />
              <TextInput
                label='Middle Name'
                placeholder='Middle Name'
                {...form.getInputProps('middleName')}
              />
              <TextInput
                label='Date of Birth'
                type='date'
                placeholder='Date of Birth'
                {...form.getInputProps('dob')}
              />
            </div>
            <Textarea
              label='Biography'
              minRows={6}
              mt={20}
              autosize
              placeholder='Artist Biography'
              {...form.getInputProps('fullBiography')}
            />
            <div className='mt-8'>
              <p>Artist Status</p>
              <div className='flex gap-5'>
                <Radio
                  label='Signed Artist'
                  color='#DA3400'
                  checked={form.values.isSigned === true}
                  onChange={() => form.setFieldValue('isSigned', true)}
                />
                <Radio
                  label='Unsigned Artist'
                  color='#DA3400'
                  checked={form.values.isSigned === false}
                  onChange={() => form.setFieldValue('isSigned', false)}
                />
              </div>
            </div>
            <div className='flex justify-end'>
              <button
                type='submit'
                disabled={loading}
                className='px-8 mt-5 bg-[#DA3400] text-white py-2 rounded-lg font-bold'>
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </>
    </section>
  );
};

export default Profile;
