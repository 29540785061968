import { useEffect, useRef, useState } from 'react';
import style from '../../styles/style.module.css';
import {
  Table,
  Avatar,
  TextInput,
  LoadingOverlay,
  Loader,
  Radio,
  MultiSelect,
  PasswordInput,
  Group,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { FaArrowLeft, FaCamera } from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';

import {
  apiAllAdimns,
  apiCreateAdmin,
  apiDeleteAdmin,
  apiUpdateAdmin,
  getPresignedUrl,
} from '../../api/ApiServices';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import { RiDeleteBin6Line } from 'react-icons/ri';

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [isTableView, setIsTableView] = useState(false);
  const [isFormView, setIsFormView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedRows, setselectedRows] = useState<string[]>([]);
  const [admins, setAllAdmins] = useState<Admin[]>([]);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const fileInputRefProfile = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<{ profile: string }>({ profile: '' });
  const [currentAdmin, setCurrentAdmin] = useState<Admin | null>(null);

  const createModal = () => {
    setIsTableView(false);
    setIsFormView(true);
  };

  const closeModal = () => {
    form.reset();
    setCurrentAdmin(null);
    setProfileImage(null);
    setFiles({ profile: '' });
    setIsTableView(true);
    setIsFormView(false);
  };

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      permissions: [] as UserPermission[],
      email: '',
      gender: '',
    },
    validate: {
      firstName: (value) => (value.trim() ? null : 'First name is required'),
      lastName: (value) => (value.trim() ? null : 'Last name is required'),
      email: (value) => (value.trim() ? null : 'Email address is required'),
      phone: (value) => (value ? null : 'Date of birth is required'),
      permissions: (value) =>
        value.length > 0 ? null : 'Permissions is reqiured',
      password: (value) => {
        if (!value || value.trim() === '') {
          return null;
        }
        if (value.length < 9) {
          return 'Password must be at least 9 characters long';
        }
        if (!/[A-Z]/.test(value)) {
          return 'Password must contain at least one capital letter';
        }
        if (!/[a-z]/.test(value)) {
          return 'Password must contain at least one small letter';
        }
        if (!/[0-9]/.test(value)) {
          return 'Password must contain at least one number';
        }
        return null;
      },
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Passwords do not match' : null,
      gender: (value) => (value ? null : 'Gender is required'),
    },
  });

  const editAdmin = (id: string) => {
    const adminToEdit = admins.find((admin) => admin._id === id);
    if (adminToEdit) {
      setCurrentAdmin(adminToEdit);
      form.setValues({
        firstName: adminToEdit.firstName,
        lastName: adminToEdit.lastName,
        phone: adminToEdit.phone,
        email: adminToEdit.email,
        gender: adminToEdit.gender,
        permissions: adminToEdit?.permissions || [],
      });
      setProfileImage(adminToEdit.profileImage);
      setFiles((prev) => ({ ...prev, profile: adminToEdit.profileImage }));
      setIsFormView(true);
    }
  };

  const uploadImage = async (file: File, type: 'profile') => {
    setIsLoading(true);

    try {
      const resp = await getPresignedUrl(file.name);
      const uploadResponse = await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      if (!uploadResponse.ok) {
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          mt: '100px',
          message: 'An error occurred, please try again later.',
        });
        return;
      }
      setFiles((prev) => ({
        ...prev,
        [type]: resp.fileName,
      }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleProfileImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setProfileImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'profile');
    }
  };
  //create admin
  const allAdmin = async () => {
    setLoading(true);
    try {
      const resp = await apiAllAdimns();
      setAllAdmins(resp.admins);
      console.log(resp.admins);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
      setLoading(false);
    }
  };
  // delete admin
  const deleteAdmin = async (id: string) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this Admin ?'
    );
    if (!isConfirmed) {
      return;
    }
    setLoading(true);
    try {
      const resp = await apiDeleteAdmin(id);
      notifications.show({
        title: 'Admin Delete',
        color: 'green',
        mt: '100px',
        message: 'Admin Successfully Deleted',
      });
      allAdmin();
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    allAdmin();
  }, []);

  const handleSubmit = async () => {
    if (!files.profile) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: 'Profile is required, Please upload Profile',
      });
      return;
    }

    // Validate the form
    const validation = form.validate();
    if (validation.hasErrors) {
      return;
    }
    setSubmitting(true);

    try {
      // Create a new object with only the fields we want
      const payload: Partial<typeof form.values> & { profileImage: string } = {
        firstName: form.values.firstName,
        lastName: form.values.lastName,
        phone: form.values.phone,
        email: form.values.email,
        gender: form.values.gender,
        permissions: form.values.permissions,
        profileImage: files.profile,
      };

      // Only add password if it's not empty
      if (form.values.password) {
        payload.password = form.values.password;
        payload.passwordConfirm = form.values.passwordConfirm;
      }

      if (currentAdmin) {
        const resp = await apiUpdateAdmin(payload, currentAdmin._id);
      } else {
        const resp = await apiCreateAdmin(payload);
      }

      notifications.show({
        title: `Admin ${currentAdmin ? 'Updated' : 'Created'}`,
        color: 'green',
        mt: '100px',
        message: `Admin Successfully ${currentAdmin ? 'Updated' : 'Created'}`,
      });

      setSubmitting(false);
      closeModal();
      allAdmin();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const renderTable = () => {
    const rows = admins.map((element) => (
      <Table.Tr
        key={element._id}
        className='cursor-pointer'
        bg={selectedRows.includes(element._id) ? '#f4f2f2' : undefined}
        onClick={() => editAdmin(element._id)}>
        <Table.Td>
          <Avatar src={element.profileImage} alt='preview Image Work' />
        </Table.Td>
        <Table.Td>{element?.firstName}</Table.Td>
        <Table.Td>{element?.lastName}</Table.Td>
        <Table.Td>{element?.role?.toLocaleLowerCase()}</Table.Td>
        <Table.Td>{element?.email}</Table.Td>
        <Table.Td>{dayjs(element?.createdAt).format('D / MM / YYYY')}</Table.Td>
        <Table.Td>
          <div className='flex gap-4 justify-end'>
            <div className='bg-[#EBEBEB] rounded-full p-1.5 w-fit flex '>
              <FiEdit
                className='cursor-pointer'
                onClick={() => editAdmin(element._id)}
              />
            </div>
            <div className='bg-[#F8D6CC] rounded-full p-1.5 w-fit flex '>
              <RiDeleteBin6Line
                color='#B62B00'
                className='cursor-pointer'
                onClick={(e) => {e.stopPropagation(), deleteAdmin(element._id);}}
              />
            </div>
          </div>
        </Table.Td>
      </Table.Tr>
    ));

    return (
      <section className='border-[1px] shadow-lg p-3 rounded-lg'>
        <div className='flex justify-between mt-4 mb-8'>
          <p className='my-auto'>All Sub Admin</p>
          <div className='flex gap-6'>
            <TextInput placeholder='Search' disabled />
            <button className='bg-[#DA3400] text-white px-10 rounded-lg font-bold'>
              Search
            </button>
          </div>
        </div>
        <Table highlightOnHover>
          <Table.Thead bg='#EFF1F0' py='xl'>
            <Table.Tr>
              <Table.Th />
              <Table.Th className='py-4'>First Name</Table.Th>
              <Table.Th>Last Name</Table.Th>
              <Table.Th>Role</Table.Th>
              <Table.Th>Email Address</Table.Th>
              <Table.Th>Date Created</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </section>
    );
  };

  return (
    <section className={style.main}>
      {isFormView ? null : (
        <div className='flex justify-between mt-5'>
          <h1 className='font-bold text-lg my-auto '>Management</h1>
          <button
            onClick={createModal}
            className='bg-[#DA3400] text-white py-2 px-8 rounded-lg font-bold  my-auto'>
            Create New Admin
          </button>
        </div>
      )}

      {loading ? (
        <div className='flex justify-center text-center items-center h-[70vh] my-auto'>
          <Loader size={40} />
        </div>
      ) : admins.length === 0 ? (
        <div className='flex justify-center h-[70vh] my-auto '>
          <div className='my-auto text-center'>
            <p className='font-bold text-xl mb-3 mx-auto'>
              You haven’t created any new admin yet.
            </p>
            <button
              onClick={createModal}
              className='px-32 bg-[#DA3400] text-white py-2 rounded-lg font-bold'>
              Create New Admin
            </button>
          </div>
        </div>
      ) : isFormView ? (
        <>
          <div className='flex items-center mb-4 mt-4'>
            <button
              onClick={closeModal}
              className='mr-4 text-sm font-normal flex'>
              <span className='my-auto ml-6 mr-2'>
                <FaArrowLeft />
              </span>
              Go Back
            </button>
            <h1 className='font-bold text-lg'>
              <span className='text-sm font-normal'>Management</span> /{' '}
              {currentAdmin ? 'Edit Admin' : 'Create New Admin'}
            </h1>
          </div>
          <div className='w-4/5 mt-12 mb-20 mx-5 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] relative'>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <LoadingOverlay
                visible={submitting}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: 'red', type: 'bars' }}
              />
              <div className='mb-4'>
                <div className='flex justify-around text-center mb-10'>
                  <div className='mx-auto justify-center'>
                    <Avatar
                      size='lg'
                      src={profileImage || ''}
                      alt='profile Image Work'
                      className='mx-auto cursor-pointer'
                      onClick={() => fileInputRefProfile.current?.click()}>
                      <FaCamera />
                    </Avatar>
                    <p className='text-sm text-[#DA3400] mt-1'>
                      Upload Admin Profile Image
                    </p>
                  </div>
                </div>

                <input
                  ref={fileInputRefProfile}
                  name='profileImage'
                  id='profileImageUpload'
                  type='file'
                  accept='image/*'
                  onChange={handleProfileImageUpload}
                  className='hidden'
                />
              </div>
              <div className='grid grid-cols-2 gap-5'>
                <TextInput
                  label='First Name'
                  placeholder='First Name'
                  {...form.getInputProps('firstName')}
                />
                <TextInput
                  label='Last Name'
                  placeholder='Last Name'
                  {...form.getInputProps('lastName')}
                />
                <TextInput
                  label='Email Address'
                  type='email'
                  placeholder='Email Address'
                  {...form.getInputProps('email')}
                />
                <TextInput
                  label='Phone Number'
                  type='number'
                  placeholder='Phone Number'
                  {...form.getInputProps('phone')}
                />
                <MultiSelect
                  label='Permissions'
                  placeholder='Permissions'
                  data={[
                    'Artists - Create',
                    'Artists - List',
                    'Artists - Update',
                    'Artists - Delete',
                    'Artworks - Create',
                    'Artworks - Read',
                    'Artworks - Update',
                    'Artworks - Delete',
                    'Exhibitions - Create',
                    'Exhibitions - Read',
                    'Exhibitions - Update',
                    'Exhibitions - Delete',
                  ]}
                  {...form.getInputProps('permissions')}
                />
                <div className='space-y-5'>
                  <PasswordInput
                    label='Set Password'
                    placeholder='Set Password'
                    {...form.getInputProps('password')}
                  />
                  <PasswordInput
                    label='Confirm Password'
                    placeholder='Confirm Password'
                    {...form.getInputProps('passwordConfirm')}
                  />
                </div>
              </div>

              <div className='mt-8'>
                <Radio.Group
                  name='gender'
                  label='Gender'
                  {...form.getInputProps('gender')}>
                  <Group mt='xs'>
                    <Radio color='#DA3400' value='Male' label='Male' />
                    <Radio color='#DA3400' value='Female' label='Female' />
                  </Group>
                </Radio.Group>
              </div>
              <div className='flex justify-end'>
                <button
                  type='submit'
                  disabled={loading}
                  className='px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold'
                  onClick={handleSubmit}>
                  {currentAdmin ? 'Update Admin' : 'Create Admin'}
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className='relative'>
          <LoadingOverlay
            visible={isLoading}
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: 'red', type: 'bars' }}
          />

          <div className=' mt-10 mb-20'>{renderTable()}</div>
        </div>
      )}
    </section>
  );
};

export default Index;
