import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Login } from './components/auth/Login';
import Layout from './components/common/Layout';
import Overview from './components/dashboard/overview/Overview';
import Hero from './components/dashboard/home/hero';
import Artist from './components/dashboard/artist';
import ArtistDetails from './components/dashboard/artist/details/[id]';
import Artwork from './components/dashboard/artworks';
import OurWorks from './components/dashboard/home/our-works';
import FeaturedExhb from './components/dashboard/home/featured-exhibitions';
import FeaturedArtwork from './components/dashboard/home/featured-artwork';
import Management from './components/dashboard/management';
import Settings from './components/dashboard/settings';
import Exhibitions from './components/dashboard/exhibitions';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path='*' element={<Navigate to='/login' replace />} /> */}

        <Route path='/' element={<Navigate to='/login' replace />} />

        {/* Set login as the index route */}
        <Route index element={<Login />} />
        <Route path='/login' element={<Login />} />

        <Route
          path='/dashboard/overview'
          element={
            <Layout>
              <Overview />
            </Layout>
          }
        />
        <Route path='/dashboard/home'>
          <Route
            path='/dashboard/home/hero-section'
            element={
              <Layout>
                <Hero />
              </Layout>
            }
          />
          <Route
            path='/dashboard/home/featured-artwork'
            element={
              <Layout>
                <FeaturedArtwork />
              </Layout>
            }
          />
          <Route
            path='/dashboard/home/our-works'
            element={
              <Layout>
                <OurWorks />
              </Layout>
            }
          />
          <Route
            path='/dashboard/home/featured-exhibitions'
            element={
              <Layout>
                <FeaturedExhb />
              </Layout>
            }
          />
        </Route>

        <Route
          path='/dashboard/artists'
          element={
            <Layout>
              <Artist />
            </Layout>
          }
        />
        <Route
          path='/dashboard/artists/details/:id'
          element={
            <Layout>
              <ArtistDetails />
            </Layout>
          }
        />
        <Route
          path='/dashboard/artworks'
          element={
            <Layout>
              <Artwork />
            </Layout>
          }
        />
        <Route
          path='/dashboard/exhibitions'
          element={
            <Layout>
              <Exhibitions />
            </Layout>
          }
        />
        <Route
          path='/dashboard/management'
          element={
            <Layout>
              <Management />
            </Layout>
          }
        />
        <Route
          path='/dashboard/settings'
          element={
            <Layout>
              <Settings />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
