import { TextInput, Group, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { apiLogin } from '../api/ApiServices';
import { useNavigate } from 'react-router';
import LoginBanner from '../../assets/banner.png';
import { Notification } from '@mantine/core';
import { Link } from 'react-router-dom';

export function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [showRest, setShowRest] = useState(false);

  const handleRest = () => {
    setShowRest(true);
    notifications.show({
      title: 'Reach out to management',
      autoClose: false,

      message: (
        <>
          Contact{' '}
          <a
            href='mailto:superadmin@email.com'
            style={{ color: '#000', textDecoration: 'underline' }}>
            superadmin@email.com
          </a>
        </>
      ),
    });
  };
  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email address is required')
      .email('Invalid email'),
    password: yup.string().required('Password is required'),
  });
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: yupResolver(schema),
  });

  const navigate = useNavigate();
  // login email and password
  const login = async (params: any) => {
    try {
      setIsLoading(true);
      const resp = await apiLogin(params);
      localStorage.setItem('admin', JSON.stringify(resp.data.admin));
      localStorage.setItem('token', resp.data.token);
      navigate('/dashboard/overview');
    } catch (error: any) {
      notifications.show({
        title: 'Login Failed',
        color: 'red',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section>
      <div className='grid grid-cols-2'>
        <div className=''>
          <img
            src={LoginBanner}
            alt='login banner'
            className='h-screen w-[80%]'
          />
        </div>
        <div className='flex flex-col my auto justify-center max-w-[500px]'>
          <h1 className='text-[#DA3400] font-[600] text-[28px] text-center '>
            Welcome back!
          </h1>
          <p className='text-center mt-3 mb-10'>
            Please log into your account.
          </p>
          <form onSubmit={form.onSubmit(login)}>
            <TextInput
              label='Email address'
              radius={4}
              size='md'
              my={20}
              placeholder='Email address'
              className='!placeholder:text-xs !text-xs !active:text-xs active:border-transparent'
              {...form.getInputProps('email')}
            />
            <PasswordInput
              radius={4}
              size='md'
              label='Password'
              my={12}
              placeholder='Password'
              className='placeholder:text-sm'
              {...form.getInputProps('password')}
            />

            <Group mt='md'>
              <button
                className='bg-[#DA3400] rounded-lg my-5 h-12 text-white w-full'
                type='submit'
                disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Login'}
              </button>
            </Group>
          </form>
          <div className='mt-3 text-right'>
            <button
              className='text-lg text-black font-bold'
              onClick={handleRest}>
              Forgot password?
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
