import React, { useEffect, useMemo, useState } from 'react';
import style from '../styles/style.module.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { SlLogout } from 'react-icons/sl';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { notifications } from '@mantine/notifications';
import {
  HiHome,
  HiMiniCog6Tooth,
  HiMiniSquares2X2,
  HiPaintBrush,
  HiPhoto,
  HiUserGroup,
  HiUserPlus,
  HiWrenchScrewdriver,
} from 'react-icons/hi2';

interface SubItem {
  path: string;
  label: string;
}

interface SidebarItem {
  path?: string;
  label: string;
  icon: React.ReactNode;
  subItems?: SubItem[];
}

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>(
    {}
  );
  const [adminRole, setAdminRole] = useState<Admin>();
  const logout = async () => {
    try {
      localStorage.clear();
      navigate('/login');
    } catch (error) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        message: 'An error occurred, please try again later.',
      });
    }
  };

  const toggleExpand = (index: number): void => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    const admin_role = localStorage.getItem('admin');
    if (admin_role) {
      const parsedAdmin = JSON.parse(admin_role);
      setAdminRole(parsedAdmin);
    }
  }, []);

  const sidebarItems = useMemo(
    () => [
      {
        path: '/dashboard/overview',
        label: 'Overview',
        icon: <HiMiniSquares2X2 className='my-auto' />,
      },
      {
        label: 'Home Page',
        icon: <HiHome className='my-auto' />,
        subItems: [
          { path: '/dashboard/home/hero-section', label: 'Hero Section' },
          {
            path: '/dashboard/home/featured-artwork',
            label: 'Featured Artwork',
          },
          { path: '/dashboard/home/our-works', label: 'Our Work' },
          {
            path: '/dashboard/home/featured-exhibitions',
            label: 'Featured Exhibitions',
          },
        ],
      },
      {
        path: '/dashboard/artists',
        label: 'Artists',
        icon: <HiUserGroup className='my-auto' />,
      },
      {
        path: '/dashboard/artworks',
        label: 'Artworks',
        icon: <HiPaintBrush className='my-auto' />,
      },
      {
        path: '/dashboard/exhibitions',
        label: 'Exhibitions',
        icon: <HiPhoto className='my-auto' />,
      },
      ...(adminRole?.role === 'SUPER_ADMIN'
        ? [
            {
              path: '/dashboard/management',
              label: 'Management',
              icon: <HiUserPlus className='my-auto' />,
            },
          ]
        : []),
      {
        path: '/dashboard/settings',
        label: 'Settings',
        icon: <HiMiniCog6Tooth className='my-auto' />,
      },
      {
        // path: '/dashboard/analytics',
        label: 'Google Analytics',
        icon: <HiWrenchScrewdriver className='my-auto' />,
      },
    ],
    [adminRole]
  );

  return (
    <section className={`${style.sidebar} pt-10`}>
      <div className='grid gap-2 px-8 mt-4'>
        {sidebarItems.map((item, index) => (
          <div key={index}>
            <div
              className={`flex gap-2 my-1 cursor-pointer  ${
                item.path && location.pathname.includes(item.path || '')
                  ? '!bg-[#DA3400] rounded-3xl p-3 text-white'
                  : 'bg-transparent p-3'
              }`}
              onClick={() => item.subItems && toggleExpand(index)}>
              <span className='my-auto'>{item.icon}</span>
              {item.path ? (
                <Link to={item.path} className='bg-transparent flex-grow'>
                  {item.label}
                </Link>
              ) : (
                <span className='flex-grow'>{item.label}</span>
              )}
              {item.subItems && (
                <span className='my-auto'>
                  {expandedItems[index] ? <MdExpandLess /> : <MdExpandMore />}
                </span>
              )}
            </div>
            {item.subItems && expandedItems[index] && (
              <div className='ml-6 mt-2 grid gap-2'>
                {item.subItems.map((subItem, subIndex) => (
                  <Link
                    key={subIndex}
                    to={subItem.path}
                    className={`block my-2 ${
                      location.pathname === subItem.path
                        ? '!bg-[#DA3400] rounded-3xl p-3  text-white'
                        : ''
                    }`}>
                    {subItem.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}

        <div className='flex gap-2 mt-16 p-3'>
          <SlLogout className='my-auto text-[#DA3400]' />
          <p className='cursor-pointer text-[#DA3400]' onClick={logout}>
            Log out
          </p>
        </div>
      </div>
    </section>
  );
};

export default SideBar;
