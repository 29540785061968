import React, { useState, useRef, useEffect } from 'react';
import style from '../../../styles/style.module.css';
import {
  apiCreateHero,
  apiDeleteHero,
  apiEditHero,
  apiGetHeros,
  getPresignedUrl,
} from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { FaArrowLeft } from 'react-icons/fa6';
import { Loader, LoadingOverlay } from '@mantine/core';

const Hero: React.FC = () => {
  const [title, setTitle] = useState<string>('Hola Oparinde, 2024');
  const [image, setImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [preview, setPreview] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [heros, setHeros] = useState<Hero[]>();
  const [isFormView, setIsFormView] = useState(false);
  const [editingHero, setEditingHero] = useState<Hero | null>(null);

  const createModal = () => {
    setIsFormView(true);
    setEditingHero(null);
    setTitle('');
    setImage(null);
    setPreview(undefined);
    // setFileName('');
  };

  const editModal = (hero: Hero) => {
    setIsFormView(true);
    setEditingHero(hero);
    setTitle(hero.title);
    setFileName(hero.image);
    setImage(hero.image);
  };

  const closeModal = () => {
    setIsFormView(false);
    setEditingHero(null);
    setTitle('');
    setImage(null);
    setPreview(undefined);
    setFileName('');
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log(file, 'file');
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      setPreview(file);
      console.log(file);
      uploadImage(file);
    }
  };

  const uploadImage = async (file: File) => {
    setIsLoading(true);
    try {
      const resp = await getPresignedUrl(file.name);
      const uploadResponse = await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      if (!uploadResponse.ok) {
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          mt: '100px',
          message: 'An error occurred, please try again later.',
        });
        return;
      }
      setFileName(resp.fileName);
      setIsLoading(false);
      console.log(resp.fileName);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // get all heros
  const fetchAllHeros = async () => {
    setLoading(true);
    try {
      const resp = await apiGetHeros();
      setHeros(resp.heros);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllHeros();
  }, []);

  // final hero submit for create new hero || edit hero
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title || !image) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: 'Title and Image are required',
      });
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        title: title,
        image: fileName,
      };

      if (editingHero) {
        const response = await apiEditHero(editingHero._id, payload);
        console.log(response, 'editing hero');
      } else {
        const response = await apiCreateHero(payload);
        console.log(response, 'creating hero');
      }
      setLoading(false);
      setTimeout(() => {
        notifications.show({
          title: editingHero ? 'Hero Edited' : 'Hero Created',
          color: 'green',
          mt: '100px',
          message: editingHero
            ? 'Hero successfully Edited'
            : 'Hero Successfully Created ',
        });
      }, 5000);
      setTitle('');
      setImage(null);
      setPreview(undefined);
      closeModal();
      fetchAllHeros();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // delete hero
  const deleteHero = async (id: string) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this hero?'
    );

    if (!isConfirmed) {
      notifications.show({
        title: 'Cancel',
        mt: '100px',
        color: 'blue',
        message: 'You have successfully Cancel the action to delete ',
      });
      return;
    }
    try {
      const resp = await apiDeleteHero(id);
      notifications.show({
        title: 'Successful',
        mt: '100px',
        color: 'green',
        message: 'You have successfully deleted the hero ',
      });
      fetchAllHeros();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    }
  };

  return (
    <section className={style.main}>
      {isFormView ? null : (
        <div className='flex justify-between my-4'>
          <h1 className='font-bold text-xl my-auto'>Hero Section</h1>
          <button
            className='px-6 bg-[#DA3400] text-white py-2 rounded-lg font-bold'
            onClick={createModal}>
            Add Hero Artwork
          </button>
        </div>
      )}

      {loading ? (
        <div className='flex justify-center text-cente items-center h-[80vh] my-auto'>
          <Loader size={40} />
        </div>
      ) : isFormView ? (
        <>
          <div className='flex items-center mb-4 mt-3'>
            <button
              onClick={closeModal}
              className='mr-4 text-sm font-normal flex'>
              <span className='my-auto ml-6 mr-2'>
                <FaArrowLeft />
              </span>
              Go Back
            </button>
            <h1 className='font-bold text-lg'>
              <span className='text-sm font-normal'>Hero Section</span> /{' '}
              {editingHero ? 'Edit' : 'Add'} Image
            </h1>
          </div>
          <div className='w-[480px] mt-12 mx-5 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] relative'>
            <form onSubmit={handleSubmit}>
              <LoadingOverlay
                visible={isLoading}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: 'red', type: 'bars' }}
              />
              <div className='mb-4'>
                <label className='block mb-2 font-bold'>Title</label>
                <input
                  type='text'
                  name='title'
                  value={title}
                  onChange={handleTitleChange}
                  className='w-full p-2 border rounded'
                />
              </div>

              <div className='mb-4'>
                <label className='block mb-2 font-bold'>
                  Hero Section Image (at least one)
                </label>
                <div
                  className='border-2 border-dashed border-gray-300 p-8 text-center cursor-pointer'
                  onClick={() => fileInputRef.current?.click()}>
                  {image ? (
                    <img
                      src={image}
                      alt='Uploaded'
                      className='max-w-full h-auto'
                    />
                  ) : (
                    <>
                      <div className='text-4xl mb-2'>📷</div>
                      <p className='text-red-500'>Upload Image</p>
                    </>
                  )}
                </div>
                <input
                  ref={fileInputRef}
                  name='heroFile'
                  id='imageUpload'
                  type='file'
                  accept='image/*'
                  onChange={handleImageUpload}
                  className='hidden'
                />
              </div>

              <button
                type='submit'
                disabled={loading}
                className='w-full bg-[#DA3400] text-white py-3 rounded-lg font-bold'>
                {editingHero ? 'Edit Hero Image' : 'Add Hero Image'}
              </button>
            </form>
          </div>
        </>
      ) : heros && heros?.length === 0 ? (
        <>
          <div className='flex justify-center h-[75vh] my-auto '>
            <div className='my-auto'>
              <p className='font-bold text-xl mb-3'>
                No artwork added to the hero section yet.
              </p>
              <button
                onClick={createModal}
                className='w-full bg-[#DA3400] text-white py-3 rounded-lg font-bold'>
                {editingHero ? 'Edit Hero Image' : 'Add Hero Image'}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className='grid grid-cols-3 gap-3 mb-12'>
          {heros?.map((hero, _id) => (
            <div
              key={_id}
              className='mt-10 mx-2 p-4 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)]'>
              <img
                src={hero.image}
                className='w-full h-60 rounded-md'
                alt='hero images'
              />
              <h1 className='mt-2'>{hero.title}</h1>
              <div className='flex justify-between mt-10'>
                <button
                  onClick={() => editModal(hero)}
                  className='rounded-md py-1 px-5 bg-[#DA3400] text-white'>
                  Edit
                </button>
                <button
                  onClick={() => deleteHero(hero._id)}
                  disabled={loading}
                  className='rounded-md py-1 px-5 bg-transparent border border-[#DA3400] text-[#DA3400]'>
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default Hero;
