import { useEffect, useRef, useState } from 'react';
import style from '../../styles/style.module.css';
import {
  Avatar,
  TextInput,
  LoadingOverlay,
  Radio,
  MultiSelect,
  PasswordInput,
  Group,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { FaCamera } from 'react-icons/fa6';

import {
  apiChangePassword,
  apiUpdateLoginAdmin,
  getPresignedUrl,
} from '../../api/ApiServices';
import { useForm } from '@mantine/form';

const Index = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const fileInputRefProfile = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<{ profile: string }>({ profile: '' });
  const [role, setRole] = useState<Admin>();
  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      oldPassword: '',
      password: '',
      passwordConfirm: '',
      role: '',
      permissions: [] as UserPermission[],
      email: '',
      gender: '',
    },
    validate: {
      firstName: (value) => (value.trim() ? null : 'First name is required'),
      lastName: (value) => (value.trim() ? null : 'Last name is required'),
      email: (value) => (value.trim() ? null : 'Email address is required'),
      phone: (value) => (value ? null : 'Date of birth is required'),
      permissions: (value) =>
        value.length > 0 ? null : 'Permissions is reqiured',
      password: (value) =>
        value.trim().length > 9
          ? null
          : 'New Passowrd must be at least 9 characters long',
      oldPassword: (value) =>
        value.trim().length > 9
          ? null
          : 'Old Passowrd must be at least 9 characters long',
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Passwords do not match' : null,
      gender: (value) => (value ? null : 'Gender is required'),
    },
  });

  const changePaaword = async () => {
    setLoading(true);
    try {
      const payload = {
        ...form.values,
      };
      const resp = await apiChangePassword(payload);
      setLoading(false);
      notifications.show({
        title: 'Successful',
        mt: '100px',
        color: 'green',
        message: 'You have successfully Change password ',
      });
    } catch (error: any) {
      setLoading(false);
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    }
  };

  useEffect(() => {
    const admin = localStorage.getItem('admin');
    if (admin) {
      const parsedAdmin = JSON.parse(admin);
      form.setValues({
        firstName: parsedAdmin?.firstName,
        lastName: parsedAdmin?.lastName,
        phone: parsedAdmin?.phone,
        password: '',
        role: parsedAdmin.role,
        email: parsedAdmin?.email,
        gender: parsedAdmin?.gender,
        permissions: parsedAdmin?.permissions || [],
      });
      setProfileImage(parsedAdmin?.profileImage || null);
      setRole(parsedAdmin);
      setFiles((prev) => ({
        ...prev,
        profile: parsedAdmin?.profileImage || '',
      }));
    }
  }, []);

  const uploadImage = async (file: File, type: 'profile') => {
    setUploading(true);

    try {
      const resp = await getPresignedUrl(file.name);
      const uploadResponse = await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      if (!uploadResponse.ok) {
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          mt: '100px',
          message: 'Image upload failed. Please try again.',
        });
        return;
      }
      setFiles((prev) => ({
        ...prev,
        [type]: resp.fileName,
      }));
      setUploading(false);
    } catch (error) {
      console.log(error);
      setUploading(false);
    }
  };

  const handleProfileImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setProfileImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'profile');
    }
  };

  const handleSubmit = async () => {
    if (!files.profile) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: 'Profile is required, Please upload Profile',
      });
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        ...form.values,
        profileImage: files.profile,
      };

      const resp = await apiUpdateLoginAdmin(payload);
      localStorage.setItem('admin', JSON.stringify(payload));
      window.dispatchEvent(new Event('adminUpdated'));

      notifications.show({
        title: 'Admin Created',
        color: 'green',
        mt: '100px',
        message: 'Admin Successfully Update',
      });

      setIsLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className={style.main}>
      <div className='relative'>
        <LoadingOverlay
          visible={uploading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
          loaderProps={{ color: 'red', type: 'bars' }}
        />
        <>
          <div className='w-4/5 mt-12 mx-5 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] relative'>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <LoadingOverlay
                visible={isLoading}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: 'red', type: 'bars' }}
              />
              <div className='mb-4'>
                <div className='flex justify-around text-center mb-10'>
                  <div className='mx-auto justify-center'>
                    <Avatar
                      size='lg'
                      src={profileImage || ''}
                      alt='profile Image Work'
                      className='mx-auto cursor-pointer'
                      onClick={() => fileInputRefProfile.current?.click()}>
                      <FaCamera />
                    </Avatar>
                    <p className='text-sm text-[#DA3400] mt-1'>
                      Upload Admin Profile Image
                    </p>
                  </div>
                </div>

                <input
                  ref={fileInputRefProfile}
                  name='profileImage'
                  id='profileImageUpload'
                  type='file'
                  accept='image/*'
                  onChange={handleProfileImageUpload}
                  className='hidden'
                />
              </div>
              <div className='grid grid-cols-2 gap-5'>
                <TextInput
                  label='First Name'
                  placeholder='First Name'
                  {...form.getInputProps('firstName')}
                  disabled={role?.role !== 'SUPER_ADMIN'}
                />
                <TextInput
                  label='Last Name'
                  placeholder='Last Name'
                  {...form.getInputProps('lastName')}
                  disabled={role?.role !== 'SUPER_ADMIN'}
                />
                <TextInput
                  label='Email Address'
                  type='email'
                  placeholder='Email Address'
                  {...form.getInputProps('email')}
                  disabled={role?.role !== 'SUPER_ADMIN'}
                />
                <TextInput
                  label='Phone Number'
                  type='number'
                  placeholder='Phone Number'
                  {...form.getInputProps('phone')}
                  disabled={role?.role !== 'SUPER_ADMIN'}
                />
                <MultiSelect
                  label='Permissions'
                  placeholder='Permissions'
                  data={[
                    'Artists - Create',
                    'Artists - List',
                    'Artists - Update',
                    'Artists - Delete',
                    'Artworks - Create',
                    'Artworks - Read',
                    'Artworks - Update',
                    'Artworks - Delete',
                    'Exhibitions - Create',
                    'Exhibitions - Read',
                    'Exhibitions - Update',
                    'Exhibitions - Delete',
                  ]}
                  {...form.getInputProps('permissions')}
                  disabled
                />
                <div className='mt-8'>
                  <Radio.Group
                    name='gender'
                    label='Gender'
                    {...form.getInputProps('gender')}>
                    <Group mt='xs'>
                      <Radio
                        color='#DA3400'
                        value='Male'
                        label='Male'
                        disabled={role?.role !== 'SUPER_ADMIN'}
                      />
                      <Radio
                        color='#DA3400'
                        value='Female'
                        label='Female'
                        disabled={role?.role !== 'SUPER_ADMIN'}
                      />
                    </Group>
                  </Radio.Group>
                </div>
              </div>

              <div className='flex justify-end'>
                <button
                  type='submit'
                  disabled={isLoading}
                  className='px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold'
                  onClick={handleSubmit}>
                  Save Changes
                </button>
              </div>
            </form>
          </div>

          <div className='w-4/5 mt-20 mb-20 mx-5 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] relative'>
            <form onSubmit={form.onSubmit(changePaaword)}>
              <LoadingOverlay
                visible={loading}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: 'red', type: 'bars' }}
              />
              <div className='grid grid-cols-2 gap-5'>
                <PasswordInput
                  label='Current Password'
                  placeholder='Current Password'
                  {...form.getInputProps('oldPassword')}
                />
                <PasswordInput
                  label='New Password'
                  placeholder='New Password'
                  {...form.getInputProps('password')}
                />
                <PasswordInput
                  label='Confirm New Password'
                  placeholder='Confirm New Password'
                  {...form.getInputProps('passwordConfirm')}
                />
              </div>
              <div className='flex justify-end'>
                <button
                  type='submit'
                  disabled={isLoading}
                  className='px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold'>
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </>
      </div>
    </section>
  );
};

export default Index;
