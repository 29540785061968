import React, { useState, useRef, useEffect } from 'react';
import style from '../../styles/style.module.css';
import {
  apiCreateArtist,
  apiDeleteArtist,
  apiGetAllArtist,
  getPresignedUrl,
} from '../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { FaArrowLeft } from 'react-icons/fa6';
import {
  Tabs,
  Table,
  Loader,
  Switch,
  TextInput,
  Avatar,
  Textarea,
  Radio,
  LoadingOverlay,
} from '@mantine/core';
import dayjs from 'dayjs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IoEyeOutline } from 'react-icons/io5';
import { useForm } from '@mantine/form';
import { FaCamera } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Artists: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [artists, setArtists] = useState<Artist[]>([]);
  const [isFormView, setIsFormView] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [files, setFiles] = useState<{ preview: string; profile: string }>({
    preview: '',
    profile: '',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredArtists, setFilteredArtists] = useState<Artist[]>([]);
  const [currentFilter, setCurrentFilter] = useState<
    'all' | 'signed' | 'unsigned'
  >('all');

  const fileInputRefPreview = useRef<HTMLInputElement>(null);
  const fileInputRefProfile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let filtered = artists;

    // Apply search filter
    const lowercaseQuery = searchQuery.toLowerCase().trim();
    filtered = filtered.filter(
      (artist) =>
        artist?.firstName?.toLowerCase().includes(lowercaseQuery) ||
        artist?.lastName?.toLowerCase().includes(lowercaseQuery)
    );

    // Apply signed/unsigned filter
    switch (currentFilter) {
      case 'signed':
        filtered = filtered.filter((artist) => artist.isSigned === true);
        break;
      case 'unsigned':
        filtered = filtered.filter((artist) => artist.isSigned === false);
        break;
    }

    setFilteredArtists(filtered);
  }, [searchQuery, artists, currentFilter]);

  const closeModal = () => {
    setIsFormView(false);
    setPreviewImage(null);
    setProfileImage(null);
    form.reset();
    setFiles({ preview: '', profile: '' });
  };

  const createModal = () => {
    setIsFormView(true);
    setPreviewImage(null);
    setProfileImage(null);
    setFiles({ preview: '', profile: '' });
  };

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      dob: '',
      fullBiography: '',
      isSigned: false,
    },
    validate: {
      firstName: (value) => (value.trim() ? null : 'First name is required'),
      lastName: (value) => (value.trim() ? null : 'Last name is required'),
      dob: (value) => (value ? null : 'Date of birth is required'),
      fullBiography: (value) =>
        value.trim().length > 50
          ? null
          : 'Biography must be at least 50 characters long',
      isSigned: (value) =>
        value !== null ? null : 'Artist status is required',
    },
  });
  const handlePreviewImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setPreviewImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'preview');
    }
  };

  const handleProfileImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          setProfileImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      uploadImage(file, 'profile');
    }
  };

  const uploadImage = async (file: File, type: 'preview' | 'profile') => {
    setIsLoading(true);

    try {
      const resp = await getPresignedUrl(file.name);
      await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      const uploadResponse = await fetch(resp.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      if (!uploadResponse.ok) {
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          mt: '100px',
          message: 'An error occurred, please try again later.',
        });
        return;
      }
      setFiles((prev) => ({
        ...prev,
        [type]: resp.fileName,
      }));
      setIsLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchAllArtists = async () => {
    setLoading(true);
    try {
      const resp = await apiGetAllArtist();
      setArtists(resp.artists);
      setLoading(false);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllArtists();
  }, []);

  const handleSubmit = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      console.log('Form has errors', validationResult.errors);
      return;
    }

    if (!files.preview || !files.profile) {
      notifications.show({
        title: 'Missing Images',
        color: 'red',
        mt: '100px',
        message: 'Please upload both preview and profile images.',
      });
      return;
    }

    setLoading(true);
    try {
      const payload = {
        ...form.values,
        previewImageWork: files.preview,
        image: files.profile,
      };

      const response = await apiCreateArtist(payload as any);

      setLoading(false);
      notifications.show({
        title: 'Artist Created',
        color: 'green',
        mt: '100px',
        message: 'Artist Successfully Created ',
      });
      closeModal();
      fetchAllArtists();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteArtist = async (id: string) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this artist?'
    );
    if (!isConfirmed) {
      notifications.show({
        title: 'Cancel',
        mt: '100px',
        color: 'blue',
        message: 'You have successfully Cancel the action to delete ',
      });
      return;
    }
    try {
      await apiDeleteArtist(id);
      notifications.show({
        title: 'Successful',
        mt: '100px',
        color: 'green',
        message: 'You have successfully deleted the Artist ',
      });
      fetchAllArtists();
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    }
  };

  function truncateText(text: string, wordLimit: number): string {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  const navigate = useNavigate();

  const handleDetails = (_id: string) => {
    navigate(`/dashboard/artists/details/${encodeURIComponent(_id)}`);
  };

  const renderTable = () => {
    const rows = filteredArtists.map((element) => (
      <Table.Tr
        key={element._id}
        className='cursor-pointer'
        onClick={() => handleDetails(element._id)}>
        <Table.Td>
          <Avatar src={element.image} alt='preview Image Work' />
        </Table.Td>
        <Table.Td>{element?.firstName}</Table.Td>
        <Table.Td>{element?.lastName}</Table.Td>
        <Table.Td>{element?.middleName}</Table.Td>
        <Table.Td className=''>
          {truncateText(element?.fullBiography, 3)}
        </Table.Td>
        <Table.Td>{dayjs(element?.dob).format('D / MM / YYYY')}</Table.Td>
        <Table.Td>
          {element?.isSigned ? (
            <p className='text-[#B62B00]'>Signed</p>
          ) : (
            'Unsigned'
          )}
          {/* <Switch checked={element.isSigned === true} color='#B62B00' /> */}
        </Table.Td>
        <Table.Td>
          <div className='flex gap-1 my-auto'>
            <span className='my-auto'>{element.works as number}</span>
            <div className='bg-[#F8D6CC] rounded-full p-1 w-fit my-auto'>
              <IoEyeOutline color='#B62B00' />
            </div>
          </div>
        </Table.Td>
        <Table.Td>
          <div className='flex gap-1 my-auto'>
            <span className='my-auto'>{element.exhibitions as number}</span>
            <div className='bg-[#F8D6CC] rounded-full p-1 w-fit my-auto'>
              <IoEyeOutline color='#B62B00' />
            </div>
          </div>
        </Table.Td>
        <Table.Td>
          <div className='bg-[#F8D6CC] rounded-full p-1.5 w-fit flex '>
            <RiDeleteBin6Line
              color='#B62B00'
              className='cursor-pointer'
              onClick={(e) => {e.stopPropagation(); deleteArtist(element._id);}}
            />
          </div>
        </Table.Td>
      </Table.Tr>
    ));

    return (
      <section className='border-[1px] shadow-lg p-3 rounded-lg'>
        <div className='flex justify-between mt-4 mb-8'>
          <p className='my-auto'>All Artists</p>
          <div className='flex gap-6'>
            <TextInput
              placeholder='Search by name'
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
            />

            <button className='bg-[#DA3400] text-white px-10 rounded-lg font-bold'>
              Search
            </button>
          </div>
        </div>
        <Table highlightOnHover>
          <Table.Thead bg='#EFF1F0' py='xl'>
            <Table.Tr>
              <Table.Th />
              <Table.Th className='py-4'>First Name</Table.Th>
              <Table.Th>Last Name</Table.Th>
              <Table.Th>Middle Name</Table.Th>
              <Table.Th>Biography</Table.Th>
              <Table.Th>Date of Birth</Table.Th>
              <Table.Th>Artist Status</Table.Th>
              <Table.Th>Artworks</Table.Th>
              <Table.Th>Exhibitions</Table.Th>
              <Table.Th>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {filteredArtists.length === 0 ? (
              <Table.Tr>
                <Table.Td colSpan={10} style={{ height: '200px' }}>
                  <div className='flex justify-center items-center h-full'>
                    <p className='text-lg font-semibold text-center'>
                      No artists found.
                    </p>
                  </div>
                </Table.Td>
              </Table.Tr>
            ) : (
              rows
            )}
          </Table.Tbody>
        </Table>
      </section>
    );
  };

  return (
    <section className={style.main}>
      <div className='flex justify-between mt-4 mb-6'>
        {isFormView ? null : (
          <>
            <h1 className='my-auto font-semibold'>All Artists</h1>
            {artists && artists?.length === 0 ? null : (
              <div className='flex '>
                <button
                  className='bg-[#DA3400] text-white py-2 px-8 rounded-lg font-bold'
                  onClick={createModal}>
                  Add Artist
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {loading ? (
        <div className='flex justify-center text-center items-center h-[70vh] my-auto'>
          <Loader size={40} />
        </div>
      ) : isFormView ? (
        <>
          <div className='flex items-center mb-4'>
            <button
              onClick={closeModal}
              className='mr-4 text-sm font-normal flex'>
              <span className='my-auto ml-6 mr-2'>
                <FaArrowLeft />
              </span>
              Go Back
            </button>
            <h1 className='font-bold text-lg'>
              <span className='text-sm font-normal'>Artist</span> / Create New
              Artist
            </h1>
          </div>
          <div className='w-4/5 mt-12 mx-5 mb-20 p-6 bg-white rounded-lg shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] relative'>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <LoadingOverlay
                visible={isLoading}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: 'red', type: 'bars' }}
              />
              <div className='mb-4'>
                <div className='flex justify-around text-center mb-10'>
                  <div className='mx-auto justify-center'>
                    <Avatar
                      size='lg'
                      src={previewImage || ''}
                      alt='preview Image Work'
                      className='mx-auto cursor-pointer'
                      onClick={() => fileInputRefPreview.current?.click()}>
                      <FaCamera />
                    </Avatar>
                    <p className='text-sm text-[#DA3400] mt-1'>
                      Upload Artist Preview Image
                    </p>
                  </div>
                  <div className='mx-auto justify-center'>
                    <Avatar
                      size='lg'
                      src={profileImage || ''}
                      alt='profile Image Work'
                      className='mx-auto cursor-pointer'
                      onClick={() => fileInputRefProfile.current?.click()}>
                      <FaCamera />
                    </Avatar>
                    <p className='text-sm text-[#DA3400] mt-1'>
                      Upload Artist Profile Image
                    </p>
                  </div>
                </div>
                <input
                  ref={fileInputRefPreview}
                  name='previewImage'
                  id='previewImageUpload'
                  type='file'
                  accept='image/*'
                  onChange={handlePreviewImageUpload}
                  className='hidden cursor-pointer'
                />
                <input
                  ref={fileInputRefProfile}
                  name='profileImage'
                  id='profileImageUpload'
                  type='file'
                  accept='image/*'
                  onChange={handleProfileImageUpload}
                  className='hidden'
                />
              </div>
              <div className='grid grid-cols-2 gap-5'>
                <TextInput
                  label='First Name'
                  placeholder='First Name'
                  {...form.getInputProps('firstName')}
                />
                <TextInput
                  label='Last Name'
                  placeholder='Last Name'
                  {...form.getInputProps('lastName')}
                />
                <TextInput
                  label='Middle Name'
                  placeholder='Middle Name'
                  {...form.getInputProps('middleName')}
                />
                <TextInput
                  label='Date of Birth'
                  type='date'
                  placeholder='Date of Birth'
                  {...form.getInputProps('dob')}
                />
              </div>
              <Textarea
                label='Biography'
                minRows={6}
                mt={20}
                autosize
                placeholder='Artist Biography'
                {...form.getInputProps('fullBiography')}
              />
              <div className='mt-8'>
                <p>Artist Status</p>
                <div className='flex gap-5'>
                  <Radio
                    label='Signed Artist'
                    color='#DA3400'
                    checked={form.values.isSigned === true}
                    onChange={() => form.setFieldValue('isSigned', true)}
                  />
                  <Radio
                    label='Unsigned Artist'
                    color='#DA3400'
                    checked={form.values.isSigned === false}
                    onChange={() => form.setFieldValue('isSigned', false)}
                  />
                </div>
              </div>
              <div className='flex justify-end'>
                <button
                  type='submit'
                  disabled={loading}
                  className='px-5 mt-5 bg-[#DA3400] text-white py-3 rounded-lg font-bold'>
                  Create Artist
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          {artists && artists?.length === 0 ? (
            <>
              <div className='flex justify-center h-[70vh] my-auto '>
                <div className='my-auto text-center'>
                  <p className='font-bold text-xl mb-3 mx-auto'>
                    {' '}
                    No artist created yet.
                  </p>
                  <button
                    onClick={createModal}
                    className='px-32 bg-[#DA3400] text-white py-3 rounded-lg font-bold'>
                    Add Artist
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className='mb-16 '>
              <Tabs
                color='#DA3400'
                defaultValue='all'
                onChange={(value) =>
                  setCurrentFilter(value as 'all' | 'signed' | 'unsigned')
                }>
                <Tabs.List className='mb-7'>
                  <Tabs.Tab value='all'>All Artists</Tabs.Tab>
                  <Tabs.Tab value='signed'>Signed Artists</Tabs.Tab>
                  <Tabs.Tab value='unsigned'>Unsigned Artists</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value='all'>{renderTable()}</Tabs.Panel>
                <Tabs.Panel value='signed'>{renderTable()}</Tabs.Panel>
                <Tabs.Panel value='unsigned'>{renderTable()}</Tabs.Panel>
              </Tabs>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default Artists;
