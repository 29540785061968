import style from '../styles/style.module.css';
import Logo from '../../assets/icons/logo.png';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Avatar } from '@mantine/core';

const Header = () => {
  const [profile, setProfile] = useState<Admin>();

  const updateProfile = () => {
    const admin = localStorage.getItem('admin');
    if (admin) {
      const parsedAdmin = JSON.parse(admin);
      setProfile(parsedAdmin);
    }
  };
  useEffect(() => {
    updateProfile();
    window.addEventListener('adminUpdated', updateProfile);
    return () => {
      window.removeEventListener('adminUpdated', updateProfile);
    };
  }, []);
  return (
    <>
      <section className={style.header}>
        <div className='w-[98%] mx-auto grid grid-cols-8'>
          <div className='col-span-3 my-auto flex justify-between '>
            <Link to={'/dashboard/overview'}>
              <img src={Logo} alt='logo' width={140} height={140} />
            </Link>
            <p className='my-auto'> {dayjs().format('dddd MMMM D, YYYY')}</p>
          </div>
          <div className='my-auto col-end-11'>
            <Link to={'/dashboard/settings'}>
              <div className='flex'>
                {/* <span className='my-auto mr-10'>
                  <BsBell size={20} />
                </span> */}
                <Avatar
                  src={profile?.profileImage || ''}
                  alt='profile Image'
                  className='mx-auto cursor-pointer mr-3 my-auto'
                />
                <div>
                  <p className='font-bold p-0 m-0'>
                    {profile?.firstName} {profile?.lastName}
                  </p>
                  <span className='text-sm'>
                    {profile?.role?.toLocaleLowerCase()}
                  </span>
                </div>
                {/* <span className='my-auto ml-3'>
                  <IoMdArrowDropdown size={24} />
                </span> */}
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
