import React from 'react';
import Header from './Header';
import SideBar from './SideBar';
import style from '../styles/style.module.css';

export default function Layout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <div className={style.grid_container}>
      <Header />
      <SideBar />
      {children}
    </div>
  );
}
