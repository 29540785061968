import { useEffect, useState } from 'react';
import style from '../../styles/style.module.css';
import ArtistsIcon from '../../../assets/icons/user-icon.png';
import ExhbIcon from '../../../assets/icons/gallert-icon.png';
import ArtworkIcon from '../../../assets/icons/artist-icon.png';
import { notifications } from '@mantine/notifications';
import { apiOverview } from '../../api/ApiServices';
import { Link } from 'react-router-dom';

const Overview = () => {
  const [overViewData, setOverViewData] = useState<Overview>();
  const overview = async () => {
    try {
      const resp = await apiOverview();
      setOverViewData(resp.data);
    } catch (error: any) {
      notifications.show({
        title: 'Something went wrong',
        color: 'red',
        mt: '100px',
        message: error?.message || 'An error occurred, please try again later.',
      });
    }
  };
  useEffect(() => {
    overview();
  }, []);

  return (
    <section className={style.main}>
      <div className='flex justify-between mt-10 mb-12'>
        <h1 className='my-auto font-bold text-lg'>Dashboard Overview</h1>
        <Link
          to={'/dashboard/artists'}
          className='bg-[#DA3400] text-[#ffff] rounded-md py-2 px-5'>
          Create New Artist
        </Link>
      </div>
      <div className='grid grid-cols-3 gap-8'>
        <div className=' bg-white shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] rounded-lg py-8 px-4 '>
          <p className='text-3xl mb-8 text-[#DA3400] font-[700] font-[Abhaya Libre]'>
            {overViewData?.totalArtists}
          </p>
          <p>Artists</p>
          <div className='flex justify-between'>
            <p className='my-auto'>Total signed artists </p>
            <img src={ArtistsIcon} alt='artist icon' width={40} height={40} />
          </div>
        </div>
        <div className=' bg-white shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] rounded-lg py-8 px-4 '>
          <p className='text-3xl mb-8 text-[#DA3400] font-[700] font-[Abhaya Libre]'>
            {overViewData?.totalWorks}
          </p>
          <p>Artworks</p>
          <div className='flex justify-between'>
            <p className='my-auto'>Total artworks </p>
            <img src={ArtworkIcon} alt='artwork icon' width={40} height={40} />
          </div>
        </div>
        <div className=' bg-white shadow-[0_2px_40px_-1px_rgba(0,0,0,0.1)] rounded-lg py-8 px-4 '>
          <p className='text-3xl mb-8 text-[#DA3400] font-[700] font-[Abhaya Libre]'>
            {overViewData?.totalExhibitions}
          </p>
          <p>Exhibitions</p>
          <div className='flex justify-between'>
            <p className='my-auto'>Total exhibitions </p>
            <img src={ExhbIcon} alt='exhb icon' width={40} height={40} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Overview;
